/* Error codes from 10 to 199 */

export const FETCH_CONTEST_DATA_PARSE = 10;
export const FETCH_CONTEST_DATA_BAD_RESPONSE = 14;

export const FETCH_SUGGESTIONS_PARSE = 20
export const FETCH_SUGGESTIONS_BAD_RESPONSE = 24;

export const PARTICIPATE_BUTTON_ACTION_NO_VK_PERMISSION = 40;

export const FETCH_ORDER_PARSE = 98;
export const FETCH_ORDER_BAD_RESPONSE = 99;