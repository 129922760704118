import React from "react";
import { connect } from 'react-redux';

import VKService from '../services/VKService';
import * as Routes from '../constants/Routes';

const styles = {
	screenContainer:{
		boxSizing: 'border-box',
		overflowY: 'scroll',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		margin: 0,
		padding: '80px 0 10px 0px',
		textAlign: 'center',
		backgroundColor: '#EDEEF0'
	},

	contentBlock:{
		display:'block', 
		float:'left', 
		width:'100%',
		marginBottom: 20,
		backgroundColor: '#fff',
		boxShadow:'0px 1px 6px 0px rgba(0,0,0,0.21)'
	},

	controlContainer:{
		display: 'block',
		boxSizing: 'border-box',
		width: '100%',
		padding: 12,
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#fff'
	},

	lineCell:{
		display: 'block',
		float: 'right',
		padding: 0,
		margin: 0,
		marginTop: 12,
		lineHeight: '130%'
	},

	thumb:{
		display: 'block',
		float: 'left',
		position: 'relative',
		width: 50,
		height: 50,
		borderRadius: '50%',
		overflow: 'hidden'
	},

	cellImg:{
		display: 'block',
		float: 'left',
		position: 'absolute',
		top: 0,
		bottom: 0,
		margin: 'auto',
		width: 50,
		maxHeight: 50,
		borderRadius: '50%'
	},

	description:{
		display: 'block',
		float: 'left',
		width: '70%',
		maxWidth: 500,
		wordWrap: 'break-word',
		padding: '4px 0 0 12px'
	},

	groupNameContainer:{
		display: 'block',
		marginBottom: 2
	},

	groupName:{
		margin: "0 0 6px 0",
		fontWeight: "normal"
	},

	groupDesc:{
		display: 'block',
		fontSize: 12,
		color: '#656565'
	},

	subscribeButtonContainer:{
		display: 'block',
		width: 160,
		margin: '12px auto 0 auto',
		textAlign: 'center'
	}
}

class BecomeMemberScreen extends React.Component {

	constructor(props){
		super(props);

		this.state={}

		this.vkService = new VKService({ ...props.vk.settings });
	}

	componentWillMount(){
		this._updateDimensions();
	}

	componentDidMount() {
		this.vkService.addSubscribeWidget((error, didAdd)=>{
			if (error){
				console.error(error);
			}
		});
		this.vkService.addSubscribeCallback((didSubscribe)=>{
			const{
				history,
				contest
			}=this.props;

			const contestId = contest.id;
			if (!!contestId && contestId !== ''){
				const url = `${Routes.PARTICIPATE}/${contestId}`;
				history.push(url);
			}
			else{
				history.push(Routes.MAIN);
			}
		});
	}

	/* Visuals */
	_getStyleForControlContainer(windowWidth){
		if (windowWidth < 330){
			return({
				...styles.controlContainer,
				height: 260
			});
		}

		return({
			...styles.controlContainer,
			height: 220,
		});
	}

	_getStyleForTitle(windowWidth){
		const style={
			width: '100%',
			minHeight: 21,
			fontWeight:'normal',
			textAlign: 'center'
		};
		// For mobiles
		if (windowWidth < 500){
			return({
				...style,
				fontSize: 18,
			});
		}

		return({
			...style,
			fontSize: 24,
		});
	}

	_getStyleForContentFrame(windowWidth){
		// For mobiles
		if(windowWidth < 500){
			return {
				display: "block",
				padding: "0 10px 0 10px"
			};
		}

		return {
			display: "block",
			width: '90%',
			maxWidth: 650,
			marginLeft: 'auto',
			marginRight: 'auto',
		};
	}

	/* Render */
	render() {
		const {
			windowWidth
		}=this.state;

		const contentFrameStyle = this._getStyleForContentFrame(windowWidth);
		const titleStyle = this._getStyleForTitle(windowWidth);
		const controlContainer = this._getStyleForControlContainer(windowWidth);

		return (
			<div style={ styles.screenContainer }>
				<div style={ contentFrameStyle }>
					<h1 style={ titleStyle }>
						Чтобы продолжить, необходимо подписаться:
					</h1>
					<div style={ styles.contentBlock }>
						<div style={ controlContainer }>
							<div style={ styles.lineCell }>
								<div style={ styles.thumb }>
									<img 
										style={ styles.cellImg }
										src="https://pp.userapi.com/c408320/v408320857/21f3/xE7Dgwy8HPY.jpg" 
										alt="ДОШКОЛЬНИК логопед педагог психологи воспитатели"
									/>
								</div>
								<div style={ styles.description }>
									<div style={ styles.groupNameContainer }>
										<h3 style={ styles.groupName }>
											ДОШКОЛЬНИК логопед педагог психологи воспитатели
										</h3>
									</div>
									<div style={ styles.groupDesc }>
										Дефектологу, психологу, муз.рук-лю, методисту, инстр. по физ-ре и РОДИТЕЛЮ! Инф. для аттестации с doshkolnik.ru! ПОДЕЛКИ И РЕЦЕПТЫ ДЕТЯМ
									</div>
								</div>
							</div>
							<div style={ styles.subscribeButtonContainer }>
								<div id="vk_subscribe"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		...state.windowReducer,
		vk: state.vk,
		contest: state.contest
	};
}

export default connect(
	mapStateToProps
)(BecomeMemberScreen);
