import React from "react";

import Button from 'material-ui/Button';

const styles = {
	button:{
		float: 'left',
		width: 200,
		marginLeft: '-3.2%',
		color: 'white',
		backgroundColor: '#FFA339'
	}
};

export default function ParticipateButton(props){
		const{
			onClick,
			disabled
		}=props;

		return(
			<Button
				onClick={onClick}
				disabled={disabled}
				variant="raised"
				style={styles.button}
			>
				Участвовать
			</Button>
		);
	}