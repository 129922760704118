import React from "react";

import Button from 'material-ui/Button';

const styles = {
	button:{
		width: 260,
		color: 'white',
		backgroundColor: '#65A0B9',
		marginBottom: 10
	}
}

export default function DownloadConditionsButton(props){
		const{ conditionsURL }=props;

		const randomState = Math.floor(Math.random()*(999-100+1)+100);
		const url = `${conditionsURL}?s=${randomState}`;

		return(
			<a href={ url } target="_blank" rel="noopener noreferrer">
				<Button
					variant="raised"
					style={ styles.button }
				>
					Положение для печати
				</Button>
			</a>
		);
	}