import React from "react";

import * as ORDER_STATUS from '../../constants/OrderStatus';

const styles = {
	fullWidthBlock:{
		display:'block', 
		float: 'left',
		width:'90%',
		margin: "0 5%",
		textAlign: 'center',
	}
}

function _renderPostLink(postLink){
	if(!postLink)
		return null;

	return(
		<div>
			<p>Ваша работа размещена.</p>
			<p>
				<a href={ postLink } target="_blank" rel="noopener noreferrer">
					Ссылка на работу
				</a>
			</p>
		</div>
	);
}

function _renderDiplomaLink(diploma){
	if (!diploma)
		return null;

	return(
		<div>
			<p>Вам выписан диплом</p>
			<p>
				<a href={ diploma.file_url } target="_blank" rel="noopener noreferrer">
					Ссылка на диплом
				</a>
			</p>
		</div>
	);
}

function _renderConditionsLink(contestId){
	if (!contestId)
		return null;

	return(
		<div>
			<p>Положение о конкурсе</p>
			<p>
				<a href={ `https://vkdoshkolnik.ru/storage/conditions/${contestId}.png` } target="_blank" rel="noopener noreferrer">
					Ссылка на положение о конкурсе
				</a>
			</p>
		</div>
	);
}

export default function OrderStatusBlock({ isFilled, status, postLink, diploma, contestId }) {

	const containerStyle = {
		...styles.fullWidthBlock,
		borderTop: '1px solid #EDEEF0'
	};

	if (!isFilled){
		return null;
	}

	if (status === ORDER_STATUS.PUBLISHED){
		return(
			<div style={ containerStyle }>
				{ _renderPostLink(postLink) }
				<br/>
				{ _renderConditionsLink(contestId) }
			</div>
		);
	}

	if (status === ORDER_STATUS.DIPLOMA_SAVED || status === ORDER_STATUS.DIPLOMA_SENT){
		return(
			<div style={ containerStyle }>
				{ _renderPostLink(postLink) }
				<br/>
				{ _renderDiplomaLink(diploma) }
				<br/>
				{ _renderConditionsLink(contestId) }
			</div>
		);
	}

	return(
		<div style={ containerStyle }>
			<p>Заявка находится на рассмотрении.</p>
		</div>
	);
}
