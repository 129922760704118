import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';

import 'core-js/es/map';
import 'core-js/es/set';
import { initVKConnect } from './services/vk.service';

import App from './App';
import configureStore from './redux';
// import registerServiceWorker from './registerServiceWorker';


initVKConnect();

const store = configureStore();
ReactDOM.render(
	<Provider store={store}>
  	<App />
	</Provider>,
  document.getElementById("root")
);
// registerServiceWorker();
