module.exports = {
	desktopContainer:{
		boxSizing: 'border-box',
		overflowY: 'scroll',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		margin: 0,
		padding: '80px 0 10px 0px',
	},

	mobileContainer:{
		padding: '80px 0 10px 0px',
	},

	contentBlock:{
		display:'block', 
		float:'left',
		width:'100%',
		textAlign: 'center',
		marginBottom: 20,
		backgroundColor: '#fff',
		boxShadow:'0px 1px 6px 0px rgba(0,0,0,0.21)'
	},

	description:{
		padding: '0 10px',
		textAlign: 'left',
		textJustify: 'kashida'
	},

	controlContainer:{
		display: 'block',
		boxSizing: 'border-box',
		width: '100%',
		padding: 12,
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#FF5F26'
	},

	tip:{
		display: 'block',
		float: 'left',
		width: '100%',
		fontSize: 16,
		margin: '20px 0 10px 0',
		fontWeight:'normal',
		textAlign: 'center'
	},

	listTitle:{
		fontSize: 20,
		color: 'white'
	},

	list:{
		backgroundColor: 'white'
	},

	textFieldContaier:{
		backgroundColor: 'white',
		padding: 12
	},

	autocompleteFieldContainer:{
		backgroundColor: 'white',
	},

	textField: {
		width: '100%',
		overflowX: 'hidden',
		overflowY: 'hidden'
	},

	proceedButton:{
		width: '100%',
		color: 'white',
		backgroundColor: '#FFA339'
	},

	activityBlock:{
		display:'block', 
		float:'left',
		width:'100%',
		textAlign: 'center'
	},

	buttonParticipateActivity:{
		width: 84,
		height: 84,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 10,
		marginBottom: 'auto'
	},

	generalErrorText:{
		textAlign: 'center',
		color: '#f44336'
	}
}
