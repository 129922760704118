import React from "react";

import APIService from '../services/APIService';

import { CircularProgress } from 'material-ui/Progress';
import ContestPreview from './ContestPreview';

export default class ContestList extends React.Component {

	constructor(props){
		super(props);

		this.state = {
			didFetchContests:false,
			isFetchError: false,
			contests: []
		}

		this.API = new APIService();
	}

	componentDidMount() {

		this._fetchContests();
	}

	_fetchContests(){
		const{ 
			shouldFetchActive,
			onError
		}=this.props;

		this.API.fetchContests(shouldFetchActive)
		.then((contests)=>{
			const didFetchContests = true;
			const isFetchError = false;
			this.setState({
				didFetchContests,
				isFetchError,
				contests
			});
		})
		.catch((error)=>{
			const isFetchError = true;
			this.setState({ isFetchError });
			onError(error)
		});
	}

	_contestWasClicked(contestId){
		const{
			onContestClick
		}=this.props;

		const{
			contests
		}=this.state;

		let chosenContest;
		for(let i=0; i<contests.length; i++){
			const contest = contests[i];
			if(contest.id === contestId){
				chosenContest = contest;
				break;
			}
		}

		onContestClick(chosenContest);
	}

	_renderContestPreviews(windowWidth, contests){

		const containerStyle = windowWidth < 500 ? { textAlign: 'center' } : null;

		const contestBlocks = [];
		contests.forEach((contest) => {
			const id = contest.id;
			const src = contest.img_url;
			contestBlocks.push(
				<ContestPreview 
					key={ id }
					id={ id } 
					src={ src }
					windowWidth={ windowWidth }
					onClick={ this._contestWasClicked.bind(this) }
				/>
			)
		});

		return(
			<div style={ containerStyle }>
				{ contestBlocks }
			</div>
		);
	}

	render() {
		const{
			windowWidth
		}=this.props;
		
		const{
			didFetchContests,
			isFetchError,
			contests
		}=this.state;

		if (isFetchError){
			return(
				<p>Не удаётся загрузить конкурсы.</p>
			);
		}

		if (!didFetchContests){
			return (
				<div>
					<CircularProgress style={{ width: 80 }} />
				</div>
			);
		}
		else if (didFetchContests && contests.length > 0){
			return this._renderContestPreviews(windowWidth, contests);
		}
		else if (didFetchContests && contests.length <= 0){
			return(
				<p>В данный момент нет активных конкурсов.</p>
			);
		}
	}
}