import React from "react";
import PropTypes from 'prop-types';

import * as Routes from '../constants/Routes';

import List, { ListItem, ListItemText } from 'material-ui/List';
import Checkbox from 'material-ui/Checkbox';
import Button from 'material-ui/Button';

const SUCCESS_URL = `${Routes.HOST}${Routes.PAYMENT_CREDITED}`;

const styles = {

	list:{
		background: '#fff',
		marginBottom: 10
	},

	checkbox:{
		color: '#FFA339'
	},

	button:{
		width: '100%',
		color: 'white',
		backgroundColor: '#FFA339'
	}
}

const listItems = [
	{
		label: "Банковской картой",
		value: "AC"
	},
	{
		label: "Яндекс.Деньгами",
		value: "PC"
	}
];

export default class PaymentBlock extends React.Component {

	constructor(props){
		super(props);

		this.state = {
			checkedIndex: 0,
			paymentType: listItems[0].value
		}
	}

	_handleToggle(checkedIndex) {
		const paymentType = listItems[checkedIndex].value;
		this.setState({ 
			checkedIndex,
			paymentType
		});
	};
 
	render() {
		const{
			receiver,
			price,
			label,
			buttonText,
			shouldOpenInNewTab
		}=this.props;
		
		const{ 
			checkedIndex,
			paymentType
		}=this.state;
		
		return (
			<div>
				<List style={ styles.list }>
					{ listItems.map((listItem, index) => (
						<ListItem
							key={ index }
							dense
							button
							onClick={()=>this._handleToggle(index)}
						>
							<Checkbox
								checked={ index === checkedIndex}
								tabIndex={-1}
								disableRipple
								style={ styles.checkbox }
							/>
							<ListItemText primary={`${listItems[index].label}`} />
						</ListItem>
					))}
				</List>

				<form 
					method="POST" 
					action="https://money.yandex.ru/quickpay/confirm.xml"
					target={ shouldOpenInNewTab ? '_blank' : '_self'}
					rel="nofollow noopener"
				>
					<input type="hidden" name="receiver" value={ receiver }/>
					<input type="hidden" name="formcomment" value="Дошкольник"/>
					<input type="hidden" name="short-dest" value="Оргвзнос"/>
					<input type="hidden" name="label" value={ label }/>
					<input type="hidden" name="quickpay-form" value="shop"/>
					<input type="hidden" name="targets" value="Оплата оргвзноса за участие в конкурсе"/>
					<input type="hidden" name="sum" value={ price } data-type="number"/>
					<input type="hidden" name="paymentType" value={ paymentType }/>
					<input type="hidden" name="successURL" value={ SUCCESS_URL }/>
					<Button
						type="submit"
						variant="raised"
						style={ styles.button }
					>
						{buttonText}
					</Button>
				</form>
			</div>
		);
	}
}

PaymentBlock.propTypes = {
	label:PropTypes.string.isRequired
};