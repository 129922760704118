import React from "react";
import * as TextService from '../../services/texts.service';

import OverviewTitle from './OverviewTitle';

const styles = {
	contentBlock:{
		display:'block', 
		float:'left',
		textAlign: 'center',
		width:'100%',
		marginBottom: 20,
		backgroundColor: '#fff',
		boxShadow:'0px 1px 6px 0px rgba(0,0,0,0.21)'
	},

	titleStyle:{
		width: '100%',
		minHeight: 21,
		fontWeight:'normal',
		textAlign: 'center'
	},

	groupContainer: {
		display: 'block',
		padding: '0px 10px'
	},

	groupImg:{
		display: 'block',
		margin: 'auto',
		width: 50,
		maxHeight: 50,
		borderRadius: '50%'
	},

	groupTitle: {
		fontWeight: 'normal'
	},

	description:{
		padding: '0 10px',
		textAlign: 'left',
		textJustify: 'kashida'
	}
}

const GROUP_NAMES = {
	55615958 : "ДОШКОЛЬНИК - логопед педагог психологи воспитатели",
	191430827 : "Дошкольник - Конкурс"
};

export default function DescriptionBlock(props){
	const{ 
		windowWidth,
		description,
		vkGroupId
	}=props;

	const groupName = GROUP_NAMES[vkGroupId];

	return(
		<div style={ styles.contentBlock }>
			<OverviewTitle h2 windowWidth={ windowWidth } text="Описание"/>
			<div style={ styles.groupContainer }>
				<p>Ваша работа будет опубликована на стене сообщества</p>
				<a 
					href={ `https://vk.com/public${vkGroupId}` }
					target="_blank"
					rel="noopener noreferrer"
				>
					<img 
						style={ styles.groupImg }
						src="https://pp.userapi.com/c408320/v408320857/21f3/xE7Dgwy8HPY.jpg" 
						alt="Аватар группы"
					/>
					<h4 style={ styles.groupTitle }>"{ groupName }"</h4>
				</a>
			</div>
			<div style={ styles.description }>
				{ TextService.breakTextIntoParagraphs(description) }
			</div>
		</div>
	);
}