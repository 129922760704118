const IMAGE_MAX_SIZE = 720;
const IMAGE_QUALITY = 0.7;

function dataURLtoBlob(dataURL) {
	// convert base64/URLEncoded data component to raw binary data held in a string
	let byteString;
	if (dataURL.split(',')[0].indexOf('base64') >= 0)
		byteString = atob(dataURL.split(',')[1]);
	else
		byteString = unescape(dataURL.split(',')[1]);
	// separate out the mime component
	const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
	// write the bytes of the string to a typed array
	let ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ia], {type:mimeString});
}

function resizeImage(image, maxWidth, maxHeight, quality) {
	const canvas = document.createElement('canvas');

	let width = image.width;
	let height = image.height;

	if (width > height) {
		if (width > maxWidth) {
			height = Math.round(height * maxWidth / width);
			width = maxWidth;
		}
	}
	else {
		if (height > maxHeight) {
			width = Math.round(width * maxHeight / height);
			height = maxHeight;
		}
	}

	canvas.width = width;
	canvas.height = height;

	const ctx = canvas.getContext("2d");
	ctx.drawImage(image, 0, 0, width, height);
	const URL = canvas.toDataURL("image/jpeg", quality);
	const blob = dataURLtoBlob(URL);
	return blob;
}

function resize (file, callback) {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = function (event) {
		const dataUrl = event.target.result;
		const image = new Image();
		image.src = dataUrl;
		image.onload = function () {
			const resizedImgBlob = resizeImage(
				image,
				IMAGE_MAX_SIZE,
				IMAGE_MAX_SIZE,
				IMAGE_QUALITY
			);
			if (callback)
				callback(resizedImgBlob);
		};
	};
}

export function decreaseImageResolution(rawImages){
	return new Promise((resolve, reject)=>{
		const numberOfImages = rawImages.length;
		let fullImagesData = [];
		
		for (let i=0; i < numberOfImages; i++){
			const image = rawImages[i];
			resize(image, (resizedImgBlob)=>{
				fullImagesData = [...fullImagesData, resizedImgBlob];
				if (fullImagesData.length >= numberOfImages){
					return resolve(fullImagesData);
				}
			});
		}
	});
}