import React, { Component } from "react";

import{
	setItem,
	unwrapSavedFieldValue
} from '../../services/localStorage.service';
import{
	trimSpaces
} from '../../services/texts.service';

import Button from 'material-ui/Button';
import TextField from 'material-ui/TextField';

const styles = {
	controlContainer:{
		display: 'block',
		boxSizing: 'border-box',
		width: '100%',
		padding: 12,
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#FF5F26'
	},

	listTitle:{
		fontSize: 20,
		color: 'white'
	},

	list:{
		backgroundColor: 'white'
	},

	textFieldContaier:{
		backgroundColor: 'white',
		padding: 12
	},

	textField: {
		width: '100%',
		overflowX: 'hidden',
		overflowY: 'hidden'
	},

	addButtonContainer:{
		display: 'block',
		padding: 10
	},

	proceedButton:{
		width: '100%',
		color: 'white',
		backgroundColor: '#FFA339'
	}
}

const MAX_NUMBER_OF_PARTICIPANTS = 2;

function nameLabel(isKidContest, i){
	if (!isKidContest)
		return `Ф.И.О. ${i+1}`;

	if (isKidContest && i === 0)
		return "Ф.И.О. ребёнка";

	if (isKidContest && i === 1)
		return "Ф.И.О. куратора";
}

function descriptionLabel(isKidContest, i){
	if (!isKidContest)
		return 'Должность';

	if (isKidContest && i === 0)
		return "Возраст ребёнка";

	if (isKidContest && i === 1)
		return "Должность куратора";
}

export default class ParticipantsBlock extends Component {

	constructor(props){
		super(props);

		// Unwrap saved field values
		const numberOfParticipants = unwrapSavedFieldValue('numberOfParticipants', 1);
		const participant0 = unwrapSavedFieldValue('participant0');
		const description0 = unwrapSavedFieldValue('description0');
		const participant1 = unwrapSavedFieldValue('participant1');
		const description1 = unwrapSavedFieldValue('description1');

		const textFields = {
			participant0: {
				value: participant0
			},
			description0: {
				value: description0
			},
			participant1: {
				value: participant1
			},
			description1: {
				value: description1
			}
		};

		this.state = {
			numberOfParticipants,
			textFields
		}
	}

	_updateParent(numberOfParticipants){
		const{
			textFields
		}=this.state;

		let areFieldsValid = true;
		const fieldKeys = Object.keys(textFields);

		for(let i=0; i < numberOfParticipants * 2; i++){

			const key = fieldKeys[i];
			const field = textFields[key];
			if (/participant{1,9}\w/.test(key)){
				areFieldsValid = trimSpaces(field.value) !== "";
			}
			this.props.onTextChange(key, field.value);
		}

		this.props.onNumberChange(numberOfParticipants);
		this.props.onValidationStatusChange(areFieldsValid);
	}

	componentDidMount() {
		this._updateParent(this.state.numberOfParticipants);
	}

	/* Interactions */
	_handleTextFieldChange(textFieldKey, text){
		const textFields = { ...this.state.textFields };
		const trimmedText = trimSpaces(text);

		const textField = textFields[textFieldKey];
		textField.value = trimmedText;

		// Save string
		setItem(textFieldKey, trimmedText);

		// Validate
		let isValid = true;

		if (/participant{1,9}\w/.test(textFieldKey)){
			if (trimmedText === "" ){
				textField.error = true;
				textField.helperText = "Обязательное поле";
				isValid = false;
			}
			else{
				textField.error = false;
				textField.helperText = "";
			}
		}

		this.setState({
			textFields
		});

		this.props.onTextChange(textFieldKey, trimmedText);
		this.props.onValidationStatusChange(isValid);
	}

	_handleAddParticipant(){
		let numberOfParticipants = this.state.numberOfParticipants;
		if (numberOfParticipants < MAX_NUMBER_OF_PARTICIPANTS){
			numberOfParticipants += 1;
		}
		this.setState({ numberOfParticipants });
		setItem('numberOfParticipants', numberOfParticipants);
		this._updateParent(numberOfParticipants);
	}

	_handleRemoveLastParticipant(){
		if (this.state.numberOfParticipants > 1){
			const numberOfParticipants = this.state.numberOfParticipants - 1;
			this.setState({ numberOfParticipants });

			setItem('numberOfParticipants', numberOfParticipants);
			this._updateParent(numberOfParticipants);
		}
	}
	/* Interactions END */

	render(){
		const{
			isKidContest,
		}=this.props;

		const{
			numberOfParticipants,
			textFields
		}=this.state;

		// Compile text fields
		const listItems = [];

		for (let i=0; i < numberOfParticipants; i++){
			const title = i !== 0 ? null :
				<p style={ styles.listTitle }>
					Участники:
				</p>;

			listItems.push(
				<div
					key={i}
					style={ styles.controlContainer }
				>
					{ title }
					<div style={ styles.textFieldContaier }>
						<TextField
							label={ nameLabel(isKidContest, i) }
							value={ textFields[`participant${i}`].value }
							onChange={ (event)=>this._handleTextFieldChange(`participant${i}`, event.target.value) }
							style={ styles.textField }
							error={ textFields[`participant${i}`] ? textFields[`participant${i}`].error : false }
							helperText={ textFields[`participant${i}`] ? textFields[`participant${i}`].helperText : "" }
							required
						/>
					</div>
					<div style={ styles.textFieldContaier }>
						<TextField
							label={ descriptionLabel(isKidContest, i) }
							value={ textFields[`description${i}`].value }
							onChange={ (event)=>this._handleTextFieldChange(`description${i}`, event.target.value) }
							style={ styles.textField }
							error={ textFields[`description${i}`] ? textFields[`description${i}`].error : false }
							helperText={ textFields[`description${i}`] ? textFields[`description${i}`].helperText : "" }
						/>
					</div>
				</div>
			);
		}

		return(
			<div>
				{ listItems }
				{ numberOfParticipants > 1 ?
					<div style={{ 
						...styles.addButtonContainer,
						...styles.controlContainer,
						marginTop: -8,
						padding:' 0 10px 20px 10px' 
					}}>
						<Button
							onClick={ this._handleRemoveLastParticipant.bind(this) }
							variant="raised"
							style={ styles.proceedButton}
						>
							{ isKidContest ? "Убрать куратора" : "Убрать второго участника" }
						</Button>
					</div>
					:
					null
				}
				{ numberOfParticipants < MAX_NUMBER_OF_PARTICIPANTS ?
					<div style={{ ...styles.addButtonContainer, ...styles.controlContainer }}>
						<Button
							onClick={ this._handleAddParticipant.bind(this) }
							variant="raised"
							style={ styles.proceedButton }
						>
							{ isKidContest ? "Добавить куратора" : "Добавить второго участника" }
						</Button>
					</div>
					:
					null
				}
			</div>
		);
	}
}