export const APP_ID = 6397254;

export const GROUP_PHOTOPUB_ID = '75111862';
export const GROUP_DOSHKOLNIK_ID = '55615958';
export const GROUP_DOSHKOLNIK_KONKURS_ID = '191430827';

// export const ALBUM_PHOTOPUB_ID = '260573955';
// export const ALBUM_DOSHKOLNIK_ID = '260663158';
// export const ALBUM_DOSHKOLNIK_KONKURS_ID = '269038748';

// Object consists of "Group Id : Album Id" pair
export const ALBUM_ID = {
	// Doshkolnik:
	// GroupId - AlbumId
	'55615958' : '279207191',
	// Doshkolnik konkurs:
	// GroupId - AlbumId
	'191430827' : '275336173',
	// PhotoPub:
	// GroupId - AlbumId
	'75111862' : '260573955'
};

export const MAX_NUMBER_OF_PHOTOS_PER_REQUEST = 2;
export const MAX_NUMBER_OF_PHOTOS = 10;

export const GROUPS_URLS = {
	55615958 : (wallPostId) => `https://vk.com/doshkolnikru?w=wall-${GROUP_DOSHKOLNIK_ID}_${wallPostId}`,
	191430827: (wallPostId) => `https://vk.com/doshkolnikkonkurs?w=wall-${GROUP_DOSHKOLNIK_KONKURS_ID}_${wallPostId}`
};