/*
 *	Yandex Metrika
 */ 
export const METRIKA = {
	GOALS: {
		HOME: {
			CONTEST: {
				CLICK: "HOME_CONTEST_CLICK"
			}
		},
		OVERVIEW: {
			PARTICIPATE: {
				CLICK: "OVERVIEW_PARTICIPATE_CLICK"
			}
		},
		PARTICIPATE: {
			PARTICIPATE: {
				CLICK: "PARTICIPATE_PARTICIPATE_CLICK"
			},
			ORDER:{
				FILLED: "PARTICIPATE_ORDER_FILLED"
			}
		}
	}
};