import { combineReducers } from 'redux';

import windowReducer from './window';
import vk from './vk';
import contests from './contests';
import orders from './orders';

const rootReducer = combineReducers({
		windowReducer,
    vk,
    contests,
    orders
});

export default rootReducer;
