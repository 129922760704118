import * as VKConfig from '../config/VK';
import * as ErrorCodes from '../constants/errors/VKServiceErrors';


function VKInitCallback() {
	// API initialization succeeded 
	_showMessage("VK API init succeeded");

	if (!this.checkPhotoUploadPermission() && this.settings.vk_viewer_group_role === 'admin') {
		// Ask admin permissions for app (photos, messages, docs )
		window.VK.callMethod("showGroupSettingsBox", +4+4096+131072);
	}
};

function VKInitErrorCallback() {
	// API initialization failed 
	// Can reload page here
	_showError("VK API init FAILED");
};

export default class VKService {

	constructor(vk_settings={}) {

		this.settings = vk_settings;

		if (window.VK) {
			// Dirty fix:
			window.name = 'fXDe6f78';
			window.id = 'fXD';
			return window.VK.init(VKInitCallback.bind(this), VKInitErrorCallback.bind(this), '5.73');
		}
		else {
			_showError("No VK object");
		}
	}

	// updateProps(vk_settings={}) {
	// 	this.settings = vk_settings;
	// }

	/* Callbacks */
	addSubscribeCallback(callback) {
		if (window.VK) {
			if (window.VK.Observer) {
				window.VK.Observer.subscribe('widgets.subscribed', ()=>{ 
					if (!!callback)
						callback(true);
				});
			}
			else {
				_showError("No VK.Observer object");
			}
		}
		else {
			_showError("addSettingsCallback: No VK object");
		}
	}

	addSettingsCallback(callback) {
		if (window.VK) {
			window.VK.addCallback('onSettingsChanged', (settings)=>{ 
				_showMessage("Settings changed: " + settings);
				if (!!callback)
					callback(settings);
			});
		}
		else {
			_showError("addSettingsCallback: No VK object");
		}
	}

	/* Helpers */
	composeWallUrl(groupId, postId) {
		return `https://vk.com/public${groupId}?w=wall-${groupId}_${postId}`;
	}

	/* API */
	checkPhotoUploadPermission() {
		console.log({ set: this.settings });
		return this.settings.vk_access_token_settings.indexOf('photos') !== -1;
	}

	checkIfAppUser() {
		return parseInt(this.settings.vk_is_app_user) === 1 ? true : false;
	}

	askForPhotoUploadPermission() {
		if (!this.checkPhotoUploadPermission()) {
			window.VK.callMethod("showSettingsBox", 4);
		}
	}

	getUploadServer(groupId) {
		return new Promise((resolve, reject) => {
			if (window.VK) {
				if (this.checkPhotoUploadPermission()) {

					const albumId = VKConfig.ALBUM_ID[groupId];
					const params = { 
						"album_id": albumId,
						"group_id": groupId
					};
					
					window.VK.api("photos.getUploadServer", params, (data)=>{
						if (data.error) {
							console.error("VKService getUploadServer error", data.error);
							const error = { 
								msg: data.error,
								code: ErrorCodes.GET_UPLOAD_SERVER_VK_API_ERROR
							};
							return reject(error);
						}
						else {
							return resolve(data.response);
						}
					});
				}
				else {
					const error = { 
						msg:"No permissions for photo uploads",
						code: ErrorCodes.GET_UPLOAD_SERVER_NO_PERMISSION_FOR_PHOTO_UPLOAD
					};
					_showError(error.msg);
					return reject(error);
				}
			}
			else {
				const error = { 
					msg: "No VK object",
					code: ErrorCodes.NO_VK_OBJECT
				};
				_showError(error.msg);
				return reject(error);
			}
		});
	}

	/* 
		params - object
	*/
	savePhotos(params) {
		return new Promise((resolve, reject) => {
			if (params.constructor === Object) {
				if (window.VK) {
					window.VK.api("photos.save", params, (data)=>{
						if (data.error) {
							console.error("VKService savePhotos error", data.error);
							const error = { 
								msg: data.error,
								code: ErrorCodes.SAVE_PHOTOS_VK_API_ERROR
							};
							return reject(error);
						}
						else {
							return resolve(data.response);
						}
					});
				}
				else {
					const error = { 
						msg: "No VK object",
						code: ErrorCodes.NO_VK_OBJECT
					};
					_showError(error.msg);
					return reject(error);
				}
			}
			else {
				const error = { 
					msg: "savePhotos: wrong params",
					code: ErrorCodes.SAVE_PHOTOS_PARAMS_IS_NOT_OBJECT
				};
				_showError(error.msg);
				return reject(error);
			}
		});
	}

	/* Widgets */
	addSubscribeWidget(callback) {
		if (window.VK) {
			window.VK.Widgets.Subscribe("vk_subscribe", {soft: 1}, -55615958);
			
			if (!!callback)
				callback(null, true);
		}
		else {
			const error = { 
				msg: "No VK object",
				code: ErrorCodes.NO_VK_OBJECT
			};
			_showError(error.msg);
			
			if (!!callback)
				callback(error);
		}
	}
}

function _showError(msg) {
	console.error('VKAPI Service: ' + msg);
};

function _showMessage(msg) {
	console.log('VKAPI Service: ' + msg);
};
