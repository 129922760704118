import React from "react";

import * as OrdersStatuses from '../../constants/OrderStatus';

import { CircularProgress } from 'material-ui/Progress';
import PaymentButton from '../PaymentButton';
import OrderStatusBlock from './OrderStatusBlock';

import moment from 'moment';
const dateFormatString = 'YYYY-MM-DD';

const styles = {
	imgActivity:{
		width: '50%',
		height: '50%',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '25%',
		marginBottom: 'auto'
	},

	img:{
		width: '100%',
		height: '100%'
	},

	contestTitle:{
		fontSize: '0.8em',
		lineHeight: '1.3em'
	},

	fullWidthBlock:{
		display:'block', 
		float: 'left',
		width:'90%',
		margin: "0 5%",
		textAlign: 'center',
	}
}

function getDifferenceFromToday(date){
	const date1 = moment(date, dateFormatString);
	const now = moment();

	const diff = date1.diff(now, 'days');
	const result = diff ? diff : '-';
	return result;
}

export default class OrderBlock extends React.Component {

	constructor(props){
		super(props);

		this.state={
			didLoadImage: false
		}
	}

	_handleImageLoaded(){
		const didLoadImage = true;
		this.setState({didLoadImage});
	}

	/* Render */
	_getStyleForMetaInfo(windowWidth){
		const style = {
			display:'inline-block', 
			float:'left', 
			textAlign: 'left',
			textJustify: 'inter-word'
		}
		// For mobiles
		if(windowWidth < 500){
			return({
				...style,
				width:'50%',
				fontSize: 16.5,
				paddingLeft:10
			});
		}

		return({
			...style,
			width:'60%',
			fontSize: 19,
			paddingLeft:12
		});
	}

	_getImageFrameStyle(windowWidth){
		const style = {
			display: 'inline-block',
			float: 'left',
			
			padding: 10,
			backgroundColor: '#fff'
		};

		if (windowWidth < 500){
			return({
				...style,
				width: '30%'
			});
		}
		

		return({
			...style,
			width: '22%'
		});
	}

	_getImageFrame(didLoadImage, imgUrl, windowWidth){
		const imgFrameStyle = this._getImageFrameStyle(windowWidth);

		return(
			<div style={ imgFrameStyle }>
				{ imgUrl ? <img src={imgUrl} style={ styles.img } alt="Превью работы" /> :
					<div style={{ display: 'flex'}}>
						<CircularProgress style={ styles.imgActivity } />
					</div>
				}
			</div>
		);
	}

	_renderTimeLeftBlock(isContestOver, status, endDate){

		if (!isContestOver){
			const dayDiff = getDifferenceFromToday(endDate);
			if (status === OrdersStatuses.DIPLOMA_SENT){
				return null;
			}

			if (dayDiff > 0){
				return <p>Осталось дней: { dayDiff }.</p>
			}
		}

		return <p>Конкурс окончен.</p>
	}

	_renderInfoBlock(windowWidth, contestName, workName, status, isContestOver, endDate){

		const metaInfoStyle = this._getStyleForMetaInfo(windowWidth);

		if (windowWidth < 500){
			return(
				<div>
					<div style={metaInfoStyle}>
						<h2 style={ styles.contestTitle }>
							КОНКУРС: "{contestName}"
						</h2>
						<p style={{ fontSize: "0.9em", lineHeight: "1.25em" }}>
							Ваша работа: "{ workName }"
						</p>
					</div>
					<div style={ styles.fullWidthBlock }>
						{ this._renderTimeLeftBlock(isContestOver, status, endDate) }
					</div>
				</div>
			);
		}

		return(
			<div style={metaInfoStyle}>
				<h2 style={ styles.contestTitle }>
					КОНКУРС: "{contestName}"
				</h2>
				<p>Ваша работа: "{ workName }"</p>
				{ this._renderTimeLeftBlock(isContestOver, status, endDate) }
			</div>
		);
	}

	_renderPaymentButtonArea(isContestOver, orderId, price, isOrderFilled, onPaymentButtonClick){

		const containerStyle = {
			...styles.fullWidthBlock,
			borderTop: '1px solid #EDEEF0'
		};

		if (isContestOver && !isOrderFilled){
			return(
				<div style={ containerStyle }>
					<p>Вы не успели внести оргвзнос.</p>
				</div>
			);
		}

		if (!isOrderFilled){

			return(
				<div style={ containerStyle }>
					<p>Заявка успешно зарегестрирована.</p>
					<p>Осталось внести оргвзнос:</p>
					<PaymentButton
						orderId={ orderId }
						price={ price }
						onClick={ ()=>{ onPaymentButtonClick(orderId) } }
					/>
					<p></p>
				</div>
			);
		}

		return null;
	}
 
	render() {
		const{
			windowWidth,

			orderId,
			price,
			imgUrl,
			workName,
			isFilled,
			status,

			postLink,
			diploma,

			isContestActive,
			contestId,
			contestName,
			endDate,

			onPaymentButtonClick
		}=this.props;

		const{
			didLoadImage
		}=this.state;

		const now = new Date();
		const isContestOver = !isContestActive || endDate <= now;

		return(
			<div>
				<img
					src={ imgUrl }
					onLoad={ this._handleImageLoaded.bind(this) }
					style={{ display:'none'}}
					alt="Invisible loader"
				/>
				{ this._getImageFrame(didLoadImage, imgUrl, windowWidth) }
				{ this._renderInfoBlock(windowWidth, contestName, workName, status, isContestOver, endDate) }
				{ this._renderPaymentButtonArea(isContestOver, orderId, price, isFilled, onPaymentButtonClick) }

				<OrderStatusBlock 
					isFilled={ isFilled }
					status={ status }
					postLink={ postLink }
					diploma={ diploma }
					contestId={ contestId }
				/>
			</div>
		);
	}
}
