import {
	SET_WINDOW_SIZE
} from '../types/window';

export function setWindowSize(width, height){
	return { type: SET_WINDOW_SIZE, width, height };
}

export default {
	setWindowSize
};