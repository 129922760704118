/* Error codes from 300 to 399 */

export const NO_VK_OBJECT = 301;
export const GET_UPLOAD_SERVER_NO_PERMISSION_FOR_PHOTO_UPLOAD = 310;
export const GET_UPLOAD_SERVER_VK_API_ERROR = 314;

export const SAVE_PHOTOS_PARAMS_IS_NOT_OBJECT = 320;
export const SAVE_PHOTOS_VK_API_ERROR = 324;

export const UPLOAD_RAW_PHOTOS_TO_VK_NO_VK_PERMISSION = 351;
export const UPLOAD_RAW_PHOTOS_TO_VK_PHOTOS_ARRAY_EMPTY = 353;
export const UPLOAD_RAW_PHOTOS_TO_VK_USER_NOT_MEMBER_OF_GROUP = 355;

export const UPLOAD_PHOTOS_TO_VK_THROUGH_LOCAL_SERVER_PARSE = 370;
export const UPLOAD_PHOTOS_TO_VK_THROUGH_LOCAL_SERVER_BAD_RESPONSE = 372;
export const UPLOAD_PHOTOS_TO_VK_THROUGH_LOCAL_SERVER_FILE_IS_NOT_VALID = 374;
export const UPLOAD_PHOTOS_TO_VK_THROUGH_LOCAL_SERVER_NO_FILES_DETECTED = 376;