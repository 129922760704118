import { 
  SET_DATA
} from '../types/contests';

const initialState = {
	id: undefined,
  type_id: undefined,
  vk_group_id: undefined,
  name: undefined,
  img_url: undefined,
  description: undefined,
  conditions: undefined,
  start_date: undefined,
  end_date: undefined
};

export default function contest(state=initialState, action) {

  switch (action.type) {

    case SET_DATA:{
      let newState = {...state};
      newState.id = action.data.id;
      newState.type_id = action.data.type_id;
      newState.vk_group_id = action.data.vk_group_id;
      newState.name = action.data.name;
      newState.img_url = action.data.img_url;
      newState.description = action.data.description;
      newState.conditions = action.data.conditions;
      newState.conditions_url = action.data.conditions_url;
      newState.start_date = action.data.start_date;
      newState.end_date = action.data.end_date;
      return newState;
    }
    default:
      return state;
  }
}
