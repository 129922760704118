export const DOMAIN = process.env.NODE_ENV === "production" ? "https://vkdoshkolnik.ru" : "http://localhost:8888";

export const API_URL = DOMAIN + "/api/vkapp";

export const CONTEST_URL = API_URL + '/contests';
export const CONTEST_GET_ONE_URL = CONTEST_URL + '/one';
export const CONTEST_GET_ACTIVE_URL = CONTEST_URL + '/active';
export const CONTEST_GET_INACTIVE_URL = CONTEST_URL + '/inactive';

export const SUGGEST_URL = API_URL + '/suggestions';
export const SUGGEST_FEDERAL_SUBJECTS = SUGGEST_URL + '/federal_subjects';
export const SUGGEST_CITIES = SUGGEST_URL + '/cities';
export const SUGGEST_SCHOOL_TYPES = SUGGEST_URL + '/school_types';
export const SUGGEST_SCHOOLS = SUGGEST_URL + '/schools';

export const ORDERS_URL = API_URL + '/orders';

export const PHOTOS_UPLOAD_URL = API_URL + '/photos/upload';

export const LOG_ADD = API_URL + '/logs/add';