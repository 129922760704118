import * as VKConfig from '../config/VK';
import * as ErrorCodes from '../constants/errors/VKServiceErrors';
import * as APIConstants from '../constants/API';

import bridge from '@vkontakte/vk-bridge';
import { decreaseImageResolution } from './images.service';
import fetcher from './Fetcher';
import { composeScreenError } from './errors.service';


/* Static */
export function initVKConnect() {
	// Init VK app
	bridge.send("VKWebAppInit", {});
}

export function subscribeToVKEvents(cb=()=>{}) {
	// Subscribe to native VK client events
	bridge.subscribe(({ detail }) => {
		if (!!detail.data) {
			const{ type, data } = detail;

			if (!!data.error_type)
				_showError(type, data.error_data)
			else
				_showMessage(type, data);

			cb.call(detail);
		}
	});
}

export function composeWallUrl(groupId, postId) {
	const func = VKConfig.GROUPS_URLS[groupId];
	return func(postId);
}

export default class VKService {
	constructor(vk_props) {
		this.settings = vk_props.settings;
		this.accessToken = !!vk_props.accessToken ? vk_props.accessToken : undefined;
	}

	updateProps(vk_props={}) {
		this.settings = vk_props.settings;
		this.accessToken = !!vk_props.accessToken ? vk_props.accessToken : undefined;
	}

	/* API */
	checkPhotoUploadPermission() {
		let allow = false;
		if (!!this.settings.vk_access_token_settings)
			allow = this.settings.vk_access_token_settings.indexOf('photos') !== -1;

		// console.log("checkPhotoUploadPermission", { allow:allow });
		return allow;
	}

	isGroupMember() {
		return this.settings.vk_viewer_group_role !== 'none';
	}

	isAdmin() {
		return this.settings.vk_viewer_group_role === 'admin';
	}

	askToJoinGroup() {
		const group_id = parseInt(this.settings.vk_group_id);
		return bridge.send("VKWebAppJoinGroup", { group_id:group_id });
	}

	askForPhotoUploadPermission() {
		const app_id = VKConfig.APP_ID;
		const scope = "photos";
		return bridge.send("VKWebAppGetAuthToken", { app_id:app_id, scope:scope });
	}

	askForToken() {
		const app_id = VKConfig.APP_ID;
		// Scope with 'photos'.
		return bridge.send("VKWebAppGetAuthToken", { app_id:app_id, scope: "photos" });
	}

	async getUploadServer(groupId) {
		try {
			console.log("getUploadServer");

			const albumId = VKConfig.ALBUM_ID[groupId];
			const params = _compileAPIParams({ 
				"album_id": albumId,
				"group_id": groupId
			}, this.accessToken);

			const { response } = await bridge.send("VKWebAppCallAPIMethod", {
				"method": "photos.getUploadServer",
				"request_id": "vkServiceRequestId",
				params
			});

			if (!!response)
				return response;

			const error = { 
				msg: "getUploadServer error: no response",
				code: ErrorCodes.GET_UPLOAD_SERVER_VK_API_ERROR
			};
			throw error;
		}
		catch(vkError) {
			console.error("VKService getUploadServer error:", vkError);

			const error = { 
				msg:"No permissions for photo uploads",
				code: ErrorCodes.GET_UPLOAD_SERVER_NO_PERMISSION_FOR_PHOTO_UPLOAD
			};
			_showError(error.msg);
			throw error;
		}
	}

	/* 
		photoParams - object
	*/
	async savePhotos(photoParams) {
		console.log("savePhotos");

		if (photoParams.constructor !== Object) {
			const error = { 
				msg: "savePhotos: wrong photoParams",
				code: ErrorCodes.SAVE_PHOTOS_PARAMS_IS_NOT_OBJECT
			};
			_showError(error.msg);
			throw error;
		}

		try {
			const params = _compileAPIParams(photoParams, this.accessToken);

			const { response } = await bridge.send("VKWebAppCallAPIMethod", {
				"method": "photos.save",
				"request_id": "vkServiceRequestId",
				params
			});

			if (!!response)
				return response;

			const error = { 
				msg:"savePhotos error: no response",
				code: ErrorCodes.SAVE_PHOTOS_VK_API_ERROR
			};
			throw error;
		}
		catch(vkError) {
			const error = { 
				msg:"savePhotos error",
				code: ErrorCodes.SAVE_PHOTOS_VK_API_ERROR
			};
			_showError(error.msg);

			throw error;
		}
	}

	_uploadPhotosToVKThroughLocalServer(uploadUrl, photos, callback) {
		console.log("_uploadPhotosToVKThroughLocalServer");

		const url = APIConstants.PHOTOS_UPLOAD_URL;

		// Compile data
		const data = new FormData();
		data.append('upload_url', uploadUrl);
		photos.forEach((file, index) => {
			data.append("file" + (index + 1), file);
		});

		return new Promise((resolve, reject) => {
			fetcher.post(url, data)
			.then((response) => {
				if (response.ok) {
					const json = response.data;
					const responseBody = JSON.parse(json.vk_response_body);
					return resolve(responseBody);
				}
				else if (response.status === 403) {
					const error = composeScreenError(
						'Произошла ошибка', 
						'Response status is not ok',
						ErrorCodes.UPLOAD_PHOTOS_TO_VK_THROUGH_LOCAL_SERVER_FILE_IS_NOT_VALID
					);
					return reject(error);
				}
				else if (response.status === 406) {
					const error = composeScreenError(
						'Произошла ошибка', 
						'Response status is not ok',
						ErrorCodes.UPLOAD_PHOTOS_TO_VK_THROUGH_LOCAL_SERVER_NO_FILES_DETECTED
					);
					return reject(error);
				}
				else {
					const error = composeScreenError(
						'Произошла ошибка', 
						'Response status is not ok',
						ErrorCodes.UPLOAD_PHOTOS_TO_VK_THROUGH_LOCAL_SERVER_BAD_RESPONSE
					);
					return reject(error);
				}
			})
			.catch((ex) => {
				const error = composeScreenError(
					'Произошла ошибка',
					ex,
					ErrorCodes.UPLOAD_PHOTOS_TO_VK_THROUGH_LOCAL_SERVER_PARSE
				);
				return reject(error);
			});
		});
	}

	async uploadRawPhotosToVK(rawPhotos, vkGroupId) {
		if (process.env.NODE_ENV === 'development') {
			return [];
		}

		console.log("uploadRawPhotosToVK");

		if (this.isGroupMember()) {
			if (rawPhotos.length > 0) {
				if (!this.checkPhotoUploadPermission()) {
					this.askForPhotoUploadPermission();

					const error = composeScreenError(
						"Нет доступа к ВК фото",
						"No permissions for photo uploads",
						ErrorCodes.UPLOAD_RAW_PHOTOS_TO_VK_NO_VK_PERMISSION
					);
					throw error;
				}

				try {
					const processedPhotos = await decreaseImageResolution(rawPhotos);

					const numberOfPhotos = processedPhotos.length;
					let fullPhotosData = [];

					const mx = VKConfig.MAX_NUMBER_OF_PHOTOS_PER_REQUEST;
					let numberOfPhotosLeft = numberOfPhotos;

					for (let i=0; i < numberOfPhotos; i += (numberOfPhotosLeft < mx ? numberOfPhotosLeft : mx)) {
						const lastIndex = (numberOfPhotosLeft < mx ? numberOfPhotosLeft : mx) + i;
						const photos = processedPhotos.slice(i, lastIndex);
						numberOfPhotosLeft = numberOfPhotosLeft - photos.length;
						if (numberOfPhotosLeft <= 0) {
							numberOfPhotosLeft = numberOfPhotos;
						}

						// Get url for photos upload
						const getUploadServerResponse = await this.getUploadServer(vkGroupId);
						const uploadUrl = getUploadServerResponse.upload_url;

						const uploadPhotosResponse = await this._uploadPhotosToVKThroughLocalServer(uploadUrl, photos);
						const params = {
							group_id: vkGroupId,

							album_id: getUploadServerResponse.album_id,

							server: uploadPhotosResponse.server,
							photos_list: uploadPhotosResponse.photos_list,
							hash: uploadPhotosResponse.hash
						};

						// Upload photos to VK
						const photosData = await this.savePhotos(params);

						fullPhotosData = [...fullPhotosData, ...photosData];
						if (fullPhotosData.length >= numberOfPhotos) {
							return fullPhotosData;
						}
					}
				}
				catch(GenericError) {
					const error = composeScreenError(
						"Произошла ошибка",
						GenericError.msg,
						GenericError.code
					);
					throw error;
				}
			}
			else {
				const error = composeScreenError(
					"Фото для загрузки не выбраны",
					"No photos to upload",
					ErrorCodes.UPLOAD_RAW_PHOTOS_TO_VK_PHOTOS_ARRAY_EMPTY
				);
				throw error;
			}
		}
		else {
			const error = composeScreenError(
				"Вы не подписаны на группу",
				"User is not a member of community",
				ErrorCodes.UPLOAD_RAW_PHOTOS_TO_VK_USER_NOT_MEMBER_OF_GROUP
			);
			throw error;
		}
	}
};

function _showError(msg="", errorObject={}) {
	console.error(`VK Service error: ${msg}`, errorObject);
};

function _showMessage(msg) {
	console.log(`VK Service: ${msg}`);
};

function _compileAPIParams(params, access_token) {
	return {
		...params,
		"v": "5.103",
		"access_token": access_token
	};
}
