import React from "react";

import { CircularProgress } from 'material-ui/Progress';

import moment from 'moment';
const dateFormatString = 'DD-MM-YYYY';

const styles = {
	container:{
		float: 'left'
	},

	imgActivity:{
		width: '50%',
		height: '50%',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '25%',
		marginBottom: 'auto'
	},

	img:{
		width: '100%',
		height: '100%',
	},

	fullWidthBlock:{
		display: 'block',
		width: '100%',
		marginTop: '36vw',
		textAlign: 'center'
	}
}

export default class ContestMetaBlock extends React.Component {

	constructor(props){
		super(props);

		this.state={
			didLoadImage: false
		}
	}

	_handleImageLoaded(){
		const didLoadImage = true;
		this.setState({ didLoadImage });
	}

	_getStyleForDateBlock(windowWidth){
		const style = {
			display:'inline-block', 
			float:'left', 
			width:'60%', 
			textAlign: 'justify',
			textJustify: 'inter-word'
		}
		
		// For mobiles
		return(windowWidth < 500 ? 
			{
				...style,
				fontSize: 16.5,
				paddingLeft:10
			}
			:
			{
				...style,
				fontSize: 19,
				paddingLeft:12
			}
		);
	}

	_parseDate(dateFromResponse){
		const result = dateFromResponse.replace(/-/g, ".");
		return result;
	}

	_getDifferenceFromToday(date){
		const date1 = moment(date, dateFormatString);
		const date2 = moment();
		const diff = date1.diff(date2, 'days');
		if (diff < 0){
			return 0;
		}
		return diff ? diff : '-';
	}

	_getImageFrameStyle(windowWidth){
		const style = {
			display: 'inline-block',
			float: 'left',
			
			borderRadius: 4,
			backgroundColor: '#fff'
		};

		return(windowWidth < 500 ? 
			{ ...style,
				width: '36%'
			}
			:
			{...style,
				width: '26%'
			}
		);
	}

	_getIMGStyle(windowWidth){
		return(windowWidth < 500 ?
			{
				...styles.img,
				borderRadius: 2,
				margin: '0.3em 0.3em -0.3em 0.3em'
			}
			:
			{
				...styles.img,
				borderRadius: 4,
				margin: '0.2em 0.2em 0em 0.2em'
			}
		);
	}

	_renderImageFrame(didLoadImage, imgUrl, windowWidth){
		const imgFrameStyle = this._getImageFrameStyle(windowWidth);
		const imgStyle = this._getIMGStyle(windowWidth);

		if (didLoadImage){
			return( 
				<div style={ imgFrameStyle }>
					<img
						src={ imgUrl }
						style={ imgStyle }
						alt="Превью конкурса"
					/>
				</div>
			);
		}
		
		return(
			<div style={ imgFrameStyle }>
				<div style={{ display: 'flex'}}>
					<CircularProgress style={ styles.imgActivity } />
				</div>
			</div>
		);
	}

	_renderDateBlock(windowWidth, startDate, endDate, daysLeft){

		const style = this._getStyleForDateBlock(windowWidth);
		const dateStartString = this._parseDate(startDate);
		const dateEndString = this._parseDate(endDate);

		if (windowWidth < 500){
			return(
				<div style={ style }>
					<p>Сроки конкурса:</p>
					<p>{ dateStartString } <br/> { dateEndString }</p>
				</div>
			);
		}

		return(
			<div style={ style }>
				<p>Сроки конкурса:</p>
				<p>{ dateStartString } &mdash; { dateEndString }</p>
				<p>Осталось дней: { daysLeft }</p>
			</div>
		);
	}
 
	render() {
		const{
			windowWidth,
			imgUrl,
			startDate,
			endDate
		}=this.props;

		const{ didLoadImage }=this.state;

		const daysLeft = this._getDifferenceFromToday(endDate);

		return(
			<div style={ didLoadImage ? styles.container : {}}>
				<img
					src={ imgUrl }
					onLoad={ this._handleImageLoaded.bind(this) }
					style={{ display:'none'}}
					alt="Invisible loader"
				/>
				{ this._renderImageFrame(didLoadImage, imgUrl, windowWidth) }
				{ this._renderDateBlock(windowWidth, startDate, endDate, daysLeft) }
				{ 
					windowWidth < 500 ? 
					<div style={ styles.fullWidthBlock }>
						<p>
							Осталось дней: { daysLeft }
						</p>
					</div>
					:
					null
				}
			</div>
		);
	}
}
