import React from "react";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as APIConstants from '../constants/API';
import * as ContestsActions from '../redux/actions/contests';
import * as VKAllActions from '../redux/actions/vk';

import * as VKConfig from '../config/VK';
import * as Routes from '../constants/Routes';
import * as ContestTypes from '../constants/ContestTypes';
import * as ErrorCodes from '../constants/errors/ParticipateScreenErrors';

import APIService from '../services/APIService';
import VKService from '../services/vk.service';
import fetcher from '../services/Fetcher';
import remotelog from '../services/RemoteLogger';
import { formatDate } from '../services/dates.service';
import {
	setItem,
	unwrapSavedFieldValue,
	clearSavedValues
} from '../services/localStorage.service';
import {
	trimSpaces,
	isCityTypeMissing,
	getCityTrimmedName
} from '../services/texts.service';
import {
	METRIKA_GOALS,
	reachGoal
} from '../services/Analytics';

import ContestMetaBlock from '../components/ContestMetaBlock';
import ParticipantsBlock from '../components/participate/ParticipantsBlock'
import PaymentButton from '../components/PaymentButton';
import Button from 'material-ui/Button';

import TextField from 'material-ui/TextField';
import Snackbar from 'material-ui/Snackbar';
import { CircularProgress } from 'material-ui/Progress';
import Switch from 'material-ui/Switch';

import AutoSuggest from '../components/AutoSuggest';
import PhotoDropList from '../components/PhotoDropList';

import styles from '../components/participate/styles';



class ParticipateScreen extends React.Component {

	constructor(props) {
		super(props);

		// Unwrap saved field values
		const numberOfParticipants = unwrapSavedFieldValue('numberOfParticipants', 1);
		const participant0 = unwrapSavedFieldValue('participant0');
		const description0 = unwrapSavedFieldValue('description0');
		const participant1 = unwrapSavedFieldValue('participant1');
		const description1 = unwrapSavedFieldValue('description1');

		const federalSubject = unwrapSavedFieldValue('federalSubject');
		const city = unwrapSavedFieldValue('city');
		const schoolType = unwrapSavedFieldValue('schoolType');
		const schoolName = unwrapSavedFieldValue('schoolName');
		const workName = unwrapSavedFieldValue('workName');
		const workDescription = unwrapSavedFieldValue('workDescription');

		const participantsNamesArray = [participant0, participant1];
		const participantsDescriptionsArray = [description0, description1];

		this.state = {
			didFetchContest: false,
			isLoadingOrder: false,

			id: undefined,
			contestTypeId: undefined,
			vkGroupId: undefined,
			name: '',
			imgUrl: '',
			description: '',
			conditions: '',
			conditionsURL: '',
			startDate: '',
			endDate: '',

			customVKUserId: props.vk.params.vk_user_id,

			numberOfParticipants,
			participantsNamesArray,
			participantsDescriptionsArray,

			textFields: {},
			textFieldsAreValid: true,

			isFederalSubjectHidden: false,

			validateVKAuth: true,

			federalSubject,
			city, 
			schoolType,
			schoolName,
			workName,
			workDescription,

			photos: [],
			photoDropErrorText: '',

			federalSubjectsSuggestions:[],
			citiesSuggestions:[],
			schoolTypesSuggestions: [],
			schoolsSuggestions: [],

			orderId: '',
			shouldShowPaymentBlock: false,

			errorText: '',
			isErrorOpen: false
		}

		this.API = new APIService();
		this.vkService = new VKService({ ...props.vk });

		this.paymentCheckTimer = null;
	}

	componentWillUnmount() {
		clearInterval(this.paymentCheckTimer);
	}

	componentDidMount() {
		// Check if contest was transfered:
		const {
			match,
			contest
		}=this.props;

		// Get current contest id
		const id = match.params.id;

		if (!contest.id || contest.id !== id) {
			// Fetch contest data:
			if (!!id && id !== '')
				this._fetchContestData(id);
		}
		else
			this._saveContestData(contest);

		setTimeout(()=>{ 
			this._scrollUp();
		}, 150);
	}

	componentWillReceiveProps(nextProps) {
		this.vkService.updateProps({ ...nextProps.vk });
	}

	_scrollUp() {
		// TODO
	}

	_startOrderStatusFetchTimer(orderId) {
		this.paymentCheckTimer = setInterval(() => this._fetchOrderStatus(orderId), 420);
	}

	_setTextFieldRef(textFieldId, ref) {

		let textFields = { ...this.state.textFields };

		if (!textFields[textFieldId]) {
			textFields[textFieldId] = {
				ref:ref
			};
		}
		else {
			textFields[textFieldId].ref = ref;
		}
		
		this.setState({ textFields });
	}

	_removeTextFieldRef(textFieldId) {
		let textFields = { ...this.state.textFields };
		if (textFields[textFieldId])
			delete textFields[textFieldId];

		this.setState({ textFields });
	}

	/* Network */
	_fetchContestData(id) {
		this.API.fetchContestData(id)
		.then( this._saveContestData.bind(this) )
		.catch(error => {
			this._showError(error.displayMSG, error.code);
			return remotelog.error(error.logMSG);
		});
	}

	_saveContestData(contest) {

		const didFetchContest = true;
		const id = contest.id;
		const contestTypeId = contest.type_id; 
		const vkGroupId = contest.vk_group_id;
		const name = contest.name;
		const imgUrl = contest.img_url;
		const description = contest.description;
		const conditions = contest.conditions;
		const conditionsURL = contest.conditions_url;
		// Parse dates
		const startDate = formatDate(contest.start_date);
		const endDate = formatDate(contest.end_date);

		// Save contest data globally
		this.props.chosenContestActions.setContestData(contest);
		// Save contest data locally
		this.setState({ 
			didFetchContest,
			id,
			contestTypeId,
			vkGroupId,
			name,
			imgUrl,
			description,
			conditions,
			conditionsURL,
			startDate,
			endDate
		});
	}

	_fetchSuggestions(textFieldKey, inputValue) {

		if (textFieldKey !== "federalSubject" && textFieldKey !== 'city' && textFieldKey !== "schoolType")
			return;

		let url;
		let inputValueClear = inputValue.trim();
		switch(textFieldKey) {
			case "federalSubject":{
				if (inputValueClear.length < 2)
					return;

				inputValueClear = inputValue.trim().toLowerCase();
				url = APIConstants.SUGGEST_FEDERAL_SUBJECTS;
				break;
			}
			case "city":{
				if (inputValueClear.length < 2)
					return;

				url = APIConstants.SUGGEST_CITIES;
				break;
			}
			case "schoolType":{
				if (inputValueClear.length < 1) {
					return;
				}
				inputValueClear = inputValue.trim().toLowerCase();
				url = APIConstants.SUGGEST_SCHOOL_TYPES;
				break;
			}
			default:
				break;
		}
		const data = { name:inputValueClear };

		fetcher.post(url, data)
		.then((response) => {
			if (response.ok) {

				const json = response.data;

				switch(textFieldKey) {
					case "federalSubject":
						const federalSubjectsSuggestions = json.federal_subjects;
						this.setState({ federalSubjectsSuggestions });
						break;
					case "city":
						const citiesSuggestions = json.full_city_names;
						this.setState({ citiesSuggestions });
						break;
					case "schoolType":
						const schoolTypesSuggestions = json.school_types;
						this.setState({ schoolTypesSuggestions });
						break;
					default:
						break;
				}
			}
			else {
				const code = ErrorCodes.FETCH_SUGGESTIONS_BAD_RESPONSE;
				remotelog.error(`Response status is not ok. code: ${code}.`);
				this._showError('Произошла ошибка', code);
			}
		})
		.catch(error => {
			remotelog.error(error);
			this._showError('Невозможно спарсить ответ', ErrorCodes.FETCH_SUGGESTIONS_PARSE);
		});
	}

	_fetchOrderStatus(orderId) {
		this.API.fetchOrderStatus(orderId)
		.then((isFilled) => {
			if (isFilled) {
				this.props.history.push(Routes.MY_ORDERS);
			}
		})
		.catch((error) => {
			remotelog.error(error.logMSG);
			this._showError(error.displayMSG, error.code);
		});
	}

	async _askPhotoUploadPermission() {
		if (!this.vkService.checkPhotoUploadPermission()) {
			try {
				const data = await this.vkService.askForPhotoUploadPermission();
				if (!!data.access_token) {
					const accessToken = data.access_token;
					this.props.VKActions.setAccessToken(accessToken);

					const scope = data.scope;
					this.props.VKActions.setVKSettings({ ...this.props.vk_settings, vk_access_token_settings:scope });
				}
			}
			catch(error) {
				const code = ErrorCodes.PARTICIPATE_BUTTON_ACTION_NO_VK_PERMISSION;
				remotelog.error(`ParticipateScreen: No permissions for photo uploads. code: ${code}`)
				return this._showError("Нет доступа к фото ВК", code);
			}
		}
	}
	/* Network END */


	/* Inputs */
	_handleParticipantsNumberChange(numberOfParticipants) {
		this.setState({ numberOfParticipants });
	}

	_handleParticipantsFieldsChange(textFieldKey, value) {
		const participantsNamesArray = [ ...this.state.participantsNamesArray ];
		const participantsDescriptionsArray = [ ...this.state.participantsDescriptionsArray ];

		const text = !!value ? "" + value : "";

		// If name:
		if (/participant{1,9}\w/.test(textFieldKey)) {
			const index = parseInt(textFieldKey.split("participant")[1]);
			participantsNamesArray[index] = text;
		}
		// If description:
		else if(/description{1,9}\w/.test(textFieldKey)) {
			const index = parseInt(textFieldKey.split("description")[1]);
			participantsDescriptionsArray[index] = text;
		}

		this.setState({
			participantsNamesArray,
			participantsDescriptionsArray
		});
	}

	_handleTextFieldChange(textFieldKey, text) {
		let newTextFields = { ...this.state.textFields };
		const trimmedText = trimSpaces(text);

		if (!newTextFields[textFieldKey])
			newTextFields[textFieldKey] = { value: "", error: false, helperText: "" };

		newTextFields[textFieldKey].value = trimmedText;

		// Save string
		setItem(textFieldKey, trimmedText);

		if (textFieldKey === "city") {
			let city = trimmedText;
			const trimmedCity = getCityTrimmedName(trimmedText);

			if (trimmedCity !== 'Москва' && trimmedCity !== 'Санкт-Петербург') {
				const isFederalSubjectHidden = false;
				this.setState({
					isFederalSubjectHidden,
					city,
					textFields: { ...newTextFields }
				});
				// Exit function
				return;
			}
			
			let federalSubject = "";
			if (trimmedCity === 'Москва') {
				federalSubject = "Московская область";
			}
			else if (trimmedCity === 'Санкт-Петербург') {
				federalSubject = "Ленинградская область";
			}

			// Save new federal subject
			const isFederalSubjectHidden = true;
			if (!newTextFields['federalSubject'])
				newTextFields['federalSubject'] = { value: "", error: false, helperText: "" };

			newTextFields['federalSubject'].value = federalSubject;

			this.setState({
				federalSubject,
				isFederalSubjectHidden,
				city,
				textFields: { ...newTextFields }
			});

			// Save federal subject string
			setItem("federalSubject", federalSubject);
		}
		// If it is not city
		else {
			let newState = { ...this.state };
			newState[textFieldKey] = trimmedText;
			newState.textFields = newTextFields;
			this.setState(newState);
		}
	}

	_handleVKValidateChange(target, value) {
		const validateVKAuth = value;
		this.setState({ validateVKAuth });
	}

	_handlePhotosDrop(photos) {
		this._validatePhotosDrop(photos);
		this.setState({ photos });
	}

	async _handleParticipateButtonAction() {
		const {
			vkGroupId,
			customVKUserId,
			photos
		}=this.state;

		// Validate all fields
		const vTextFields = this._validateTextFields();
		const vPhotoField = this._validatePhotosDrop(photos)
		const canSend = vTextFields && vPhotoField;

		if (canSend) {
			if (!this.vkService.checkPhotoUploadPermission()) {
				return this._askPhotoUploadPermission();
			}

			// Set activity view
			this.setState({ isLoadingOrder: true });
			try {
				// Upload User photos
				const photosData = await this.vkService.uploadRawPhotosToVK(photos, vkGroupId);

				// Compile order params
				const orderParams = {
					contestId: this.state.id,
					
					numberOfParticipants: this.state.numberOfParticipants,
					participantsNamesArray: [ ...this.state.participantsNamesArray ],
					participantsDescriptionsArray: [ ...this.state.participantsDescriptionsArray ],

					federalSubject: this.state.federalSubject,
					city: this.state.city,
					schoolType: this.state.schoolType,
					schoolName: this.state.schoolName,
					workName: this.state.workName,
					workDescription: this.state.workDescription,
					photosData: photosData
				};

				// Detect if user id should change
				const skip_vk_validation = !this.state.validateVKAuth;
				const vk_user_id = skip_vk_validation && this.vkService.isAdmin() ? customVKUserId : this.props.vk.params.vk_user_id;

				// Compile VK params
				const vkParams = {
					vk_app_full_url: this.props.vk.fullURL,
					vk_user_id,
					skip_vk_validation
				};

				// Fetch place order.
				const response = await this.API.placeOrder(orderParams, vkParams);
				
				const orderPrice = response.order.price_rub;
				const orderId = response.order.id;
				this._startOrderStatusFetchTimer(orderId);

				this.setState({ 
					isLoadingOrder: false,
					shouldShowPaymentBlock: true,
					orderId,
					orderPrice
				});
				// Clear localStorage values
				clearSavedValues();

				// Analytics
				reachGoal(METRIKA_GOALS.HOME.CONTEST.CLICK);
			}
			catch(GenericError) {
				this.setState({ 
					isLoadingOrder: false
				});

				this._showError(GenericError.displayMSG, GenericError.code);
				return remotelog.error(GenericError.logMSG);
			}
		}
	}
	/* Inputs END */

	/* Validations */
	_handleChangeParticipantValidationStatus(areParticipantsValid) {
		this.setState({ areParticipantsValid });
	}

	_validateTextFields() {
		const {
			textFields,
			areParticipantsValid
		}=this.state;

		let areValid = true;

		for (let textFieldId in textFields) {
			const textField = textFields[textFieldId];

			if (textField.ref) {
				const input = textField.ref;
				const isRequired = input.required;
				const value = !!textField.value ? textField.value : input.value;
				const content = trimSpaces(value);

				if (isRequired && content === '') {
					textField.error = true;
					textField.helperText = "Обязательное поле";
					areValid = false;
				}
				else if (textFieldId === 'city' && content !== '') {

					if (isCityTypeMissing(content)) {
						textField.error = true;
						textField.helperText = `Обязательно укажите "город", "село", или "посёлок городского типа"`;
						areValid = false;
					}
					else {
						textField.error = false;
						textField.helperText = "";
					}
				}
				else if (textFieldId === 'schoolType') {

					const hasLowerCase = content.toUpperCase() !== content;
					const isTooLong = content.length > 10;

					if (hasLowerCase || isTooLong) {
						textField.error = true;
						textField.helperText = "Напишите тип сокращенно, заглавнымы буквами. Пример: ГБДОУ, МАДОУ, ХМАО и т.д.";
						areValid = false;
					}
					else {
						textField.error = false;
						textField.helperText = "";
					}
				}
				else if (textFieldId === 'schoolName' && content.length > 80) {
					textField.error = true;
					textField.helperText = "Превышено максимальное число символов 80";
					areValid = false;
				}
				else {
					textField.error = false;
					textField.helperText = "";
				}
			}
		}

		const textFieldsAreValid = areValid && areParticipantsValid;
		this.setState({
			textFields,
			textFieldsAreValid
		});

		if (!textFieldsAreValid) {
			this._scrollUp();
		}

		return textFieldsAreValid;
	}

	_validatePhotosDrop(photos) {

		const isValid = photos.length > 0;
		if (!isValid) {
			const photoDropErrorText = "Выберите хотя бы 1 фото";
			this.setState({ photoDropErrorText })
		}
		else {
			const photoDropErrorText = "";
			this.setState({ photoDropErrorText })
		}

		return isValid;
	}
	/* Validations END */

	/* Errors */
	_showError(errText, errorCode) {

		const errorText = `${errText}. Код: ${errorCode}`;

		const isErrorOpen = true;
		const isLoadingOrder = false;
		this.setState({ 
			isErrorOpen,
			errorText,
			isLoadingOrder
		});
	}

	_handleErrorCloseRequest() {
		this.setState({ isErrorOpen: false });
	}

	/* Visuals */
	_getStyleForTitle(windowWidth) {
		const style={
			width: '100%',
			minHeight: 21,
			fontWeight:'normal',
			textAlign: 'center'
		};

		// For mobiles
		if (windowWidth < 500) {
			return {
				...style,
				fontSize: 18
			};
		}

		return {
			...style,
			fontSize: 24
		};
	}

	_getStyleForContentFrame(windowWidth) {
		// For mobiles
		if (windowWidth < 500) {
			return {
				display: "block",
				padding: "0 10px 0 10px"
			};
		}
		
		return {
			display: "block",
			width: '90%',
			maxWidth: 650,
			marginLeft: 'auto',
			marginRight: 'auto'
		};
	}

	_renderAutocomplete(title, textFieldKey, value, suggestions, isHidden=false) {
		const {
			textFields
		}=this.state;

		const suggestionComponents = [];
		suggestions.forEach((suggestion)=>{
			suggestionComponents.push({ label: suggestion});
		});

		const display = isHidden ? "none" : "inherit";
		const style = {
			...styles.textFieldContaier,
			display
		};

		return(
			<div style={ style }>
				<AutoSuggest
					inputRef={ (ref) => { this._setTextFieldRef(textFieldKey, ref)}}
					placeholder={ title }
					onChange={ (value) => {
						this._handleTextFieldChange(textFieldKey, value);
						this._fetchSuggestions(textFieldKey, value);
					}}
					suggestions={ suggestionComponents }
					required={ true }
					errorText={ textFields[textFieldKey] ? textFields[textFieldKey].helperText : '' }
					value={ value }
				/>
			</div>
		);
	}

	_renderPaymentBlock(shouldShowPaymentBlock, orderId, price) {
		return( 
			<div style={ styles.contentBlock }>
				<div style={{ 
						...styles.controlContainer,
						display: shouldShowPaymentBlock ? 'block' : 'none',
						transition: 'all 0.18s linear'
					}}>
					<p style={ styles.listTitle }>
						Ваша заявка принята
					</p>
					<br/>
					<p style={ styles.listTitle }>
						Теперь внесите оргвзнос:
					</p>
					<PaymentButton
						orderId={ orderId }
						price={ price }
					/>
				</div>
			</div>
		);
	}

	_renderParticipateButtonArea(didFetchContest, isLoadingOrder, shouldShowPaymentBlock) {

		const participateButtonText = "Участвовать";
		const buttonStyle = styles.proceedButton;

		if (!this.vkService.checkPhotoUploadPermission()) {
			return(
				<div>
					<Button
						onClick={ this._askPhotoUploadPermission.bind(this) }
						variant="raised"
						style={ buttonStyle }
					>
						Разрешить доступ к фото
					</Button>
					<p/>
					<Button
						disabled={ true }
						variant="raised"
						style={{ ...buttonStyle, opacity: 0.5 }}
					>
						{ participateButtonText }
					</Button>
				</div>
			);
		}

		if (isLoadingOrder) {
			return(
				<div style={ styles.activityBlock }>
					<CircularProgress style={ styles.buttonParticipateActivity } />
				</div>
			);
		}
		else if (!shouldShowPaymentBlock) {
			return(
				<Button
					disabled={ !didFetchContest }
					onClick={ this._handleParticipateButtonAction.bind(this) }
					variant="raised"
					style={ buttonStyle }
				>
					{ participateButtonText }
				</Button>
			);
		}
	}

	_renderGeneralErrorBlock(textFieldsAreValid) {

		if (!textFieldsAreValid) {
			return(
				<p style={ styles.generalErrorText }>
					Пролистайте вверх и убедитесь, что вы заполнили все поля.
				</p>
			);
		}

		return null;
	}

	render() {
		const {
			windowWidth
		}=this.props;

		const {
			didFetchContest,
			isLoadingOrder,

			validateVKAuth,
			customVKUserId,

			contestTypeId,
			name,
			imgUrl,
			startDate, 
			endDate,

			textFields,
			textFieldsAreValid,
			isFederalSubjectHidden,

			federalSubject,
			city,
			schoolType,
			schoolName,
			workName,
			workDescription,

			photoDropErrorText,

			federalSubjectsSuggestions,
			citiesSuggestions,
			schoolTypesSuggestions,
			schoolsSuggestions,

			orderId,
			orderPrice,
			shouldShowPaymentBlock, 

			isErrorOpen,
			errorText,
		}=this.state;

		const containerStyle = windowWidth < 500 ? styles.mobileContainer : styles.desktopContainer;
		const contentFrameStyle = this._getStyleForContentFrame(windowWidth);
		const titleStyle = this._getStyleForTitle(windowWidth);

		return (
			<div style={ containerStyle }>
				<div
					style={ contentFrameStyle }
				>
					<h1 style={ titleStyle }>
						{ name }
					</h1>
					<div style={ styles.contentBlock }>
						<ContestMetaBlock
							windowWidth={ windowWidth }
							imgUrl={ imgUrl }
							startDate={ startDate }
							endDate={ endDate }
						/>
					</div>

					<h2 style={ styles.tip }>
						ЗАПОЛНИТЕ ПОЛЯ ДЛЯ УЧАСТИЯ:
					</h2>

					<div style={ styles.contentBlock }>
						<ParticipantsBlock
							isKidContest={ contestTypeId === ContestTypes.FOR_KIDS }
							onNumberChange={ this._handleParticipantsNumberChange.bind(this) }
							onTextChange={ this._handleParticipantsFieldsChange.bind(this) }
							onValidationStatusChange={ this._handleChangeParticipantValidationStatus.bind(this) }
						/>
						{ this.vkService.isAdmin() ?
							<div style={ styles.controlContainer }>
								<p style={ styles.listTitle }>
									~Admin Info:
								</p>
								<div style={ styles.textFieldContaier }>
									<TextField
										inputRef={(ref)=>{ this._setTextFieldRef("customVKUserId", ref) }}
										required
										label="VK Id участника"
										value={ customVKUserId }
										onChange={ (event)=>this._handleTextFieldChange("customVKUserId", event.target.value)}
										style={ styles.textField }
										error={ textFields["customVKUserId"] ? textFields["customVKUserId"].error : false }
										helperText={ textFields["customVKUserId"] ? textFields["customVKUserId"].helperText : "" }
									/>
								</div>
								<div style={ styles.textFieldContaier }>
									<Switch
										checked={ validateVKAuth }
										onChange={ this._handleVKValidateChange.bind(this) }
										value="validateVKAuthCheck"
									/>
								</div>
							</div>
							:
							null
						}
						<div style={ styles.controlContainer }>
							<p style={ styles.listTitle }>
								Важная информация для диплома! Полное наименование вашего учебного учреждения:
							</p>
							{ this._renderAutocomplete("Республика или область или край *", "federalSubject", federalSubject, federalSubjectsSuggestions, isFederalSubjectHidden) }
							{ this._renderAutocomplete("Город или село *", "city", city, citiesSuggestions) }
							{ this._renderAutocomplete("Тип учреждения *", "schoolType", schoolType, schoolTypesSuggestions) }
							{ this._renderAutocomplete("Название учреждения *", "schoolName", schoolName, schoolsSuggestions) }
						</div>
						<div style={ styles.controlContainer }>
						 <p style={ styles.listTitle }>
								Ваша работа:
							</p>
							<div style={ styles.textFieldContaier }>
								<TextField
									inputRef={ (ref)=>{ this._setTextFieldRef("workName", ref)}}
									required
									label="Название работы"
									value={ workName }
									onChange={ (event)=>this._handleTextFieldChange("workName", event.target.value)}
									style={ styles.textField }
									error={ textFields["workName"] ? textFields["workName"].error : false }
									helperText={ textFields["workName"] ? textFields["workName"].helperText : "" }
								/>
							</div>
							<div style={ styles.textFieldContaier }>
								<TextField
									inputRef={ (ref)=>{ this._setTextFieldRef("workDescription", ref)}}
									required
									multiline
									label="Описание работы"
									value={ workDescription }
									onChange={ (event)=>this._handleTextFieldChange("workDescription", event.target.value)}
									style={ styles.textField }
									error={ textFields["workDescription"] ? textFields["workDescription"].error : false }
									helperText={ textFields["workDescription"] ? textFields["workDescription"].helperText : "" }
								/>
								<p style={ styles.tip }>
									(Обязательно пишите подробное описание для правильной оценки вашей работы. Не забудьте указать целевую аудиторию!)
								</p>
							</div>
							<div style={ styles.textFieldContaier }></div>
							<div style={ styles.textFieldContaier }></div>
							<div style={ styles.textFieldContaier }></div>
							<div style={ styles.textFieldContaier }>
								<PhotoDropList
									maxFilesCount={ VKConfig.MAX_NUMBER_OF_PHOTOS }
									onFilesDrop={ (photos)=>{ this._handlePhotosDrop(photos) }}
									onError={ ()=>{} }
									title={ `Загрузите до ${VKConfig.MAX_NUMBER_OF_PHOTOS} фото` }
									windowWidth={ windowWidth }
									errorText={ photoDropErrorText }
								/>
							</div>
						</div>
					</div>
					{ this._renderParticipateButtonArea(didFetchContest, isLoadingOrder, shouldShowPaymentBlock) }
					{ this._renderPaymentBlock(shouldShowPaymentBlock, orderId, orderPrice) }
					{ this._renderGeneralErrorBlock(textFieldsAreValid) }
				</div>
				<Snackbar
					open={ isErrorOpen }
					message={ errorText }
					onClose={ this._handleErrorCloseRequest.bind(this) }
					autoHideDuration={ 3500 }
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		...state.windowReducer,
		contest: state.contests,
		vk: state.vk
	};
}

function mapDispatchToProps(dispatch) {
	return {
		chosenContestActions: bindActionCreators(ContestsActions, dispatch),
		VKActions: bindActionCreators(VKAllActions, dispatch)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ParticipateScreen);
