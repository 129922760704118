import {
	SET_FULL_URL,
  SET_VK_PARAMS,
  SET_VK_APP_SETTINGS,
  SET_ACCESS_TOKEN
} from '../types/vk';

const initialState = {
	fullURL: undefined,
	params: {},
	settings: {},
	accessToken: undefined
};

export default function vk(state=initialState, action) {
	switch (action.type) {
		case SET_FULL_URL: {
			const newState = { ...state, fullURL: action.vk_full_url };
			return newState;
		}

		case SET_VK_PARAMS: {
			const newState = { ...state, params: action.vk_params };
			return newState;
		}

		case SET_VK_APP_SETTINGS: {
			const newState = { ...state, settings: action.vk_settings };
			return newState;
		}

		case SET_ACCESS_TOKEN: {
			const newState = { ...state, accessToken: action.access_token };
			return newState;
		}

		default:
			return state;
	}
}
