import React  from 'react';


const CITY_PREFIX = [
	'г ',
	'Г ',
	'г.',
	'Г.',
	'гор ',
	'Гор ',
	'гор.',
	'Гор.',
	'город ',
	'Город '
];

const VILLAGE_A_PREFIX = [
	'с ',
	'С ',
	'с.',
	'С.',
	'сел.',
	'Сел.',
	'село ',
	'Село '
];

const VILLAGE_B_PREFIX = [
	'п ',
	'пос ',
	'пос.',
	'посёлок ',
	'поселок ',
	'Пос ',
	'Пос.',
	'Посёлок ',
	'Поселок ',
	'П ',
	'ПОС ',
	'ПОС.',
	'ПОСЁЛОК ',
	'ПОСЕЛОК '
];

const VILLAGE_C_PREFIX = [
	'д ',
	'д.',
	'Д ',
	'Д.'
];

const URBAN_VILLAGE_PREFIX = [
	'пгт ',
	'ПГТ ',
	'п.г.т.',
	'П.Г.Т.',
	'посёлок городского типа',
	'Посёлок городского типа',
	'поселок городского типа',
	'Поселок городского типа'
];


const EMOJI_RANGES = [
  '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
  '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
  '\ud83d[\ude80-\udeff]' // U+1F680 to U+1F6FF
];

export function breakTextIntoParagraphs(text) {
	if (text === null || text === undefined) {
		return null;
	}

	let textArray = text.split("\n\n");
	let paragraphs = [];
	textArray.forEach((paragraph, index)=>{
		paragraphs.push('<p>' + paragraph + '</p>');
	});

	const newText = paragraphs.join('');
	const newTextWithLineBreaks = newText.replace(/\n/g, "<br/>");

	const result = 
		<div dangerouslySetInnerHTML={{
			__html : newTextWithLineBreaks
		}} />;

	return result;
}

export function trimSpaces(string) {
	const trimmed = string.replace(/\s\s+/g, ' ');
	return trimmed;
}

function isPrefixPresent(text, prefixes) {
	const lowCase = text.toLowerCase();
	for (let i=0; i < prefixes.length; i++) {
		const prefix = prefixes[i];
		if (lowCase.indexOf(prefix) !== -1) {
			return true;
		}
	}
	return false;
}

function _allPrefixes() {
	return [...CITY_PREFIX, ...VILLAGE_A_PREFIX, ...VILLAGE_B_PREFIX, ...VILLAGE_C_PREFIX, ...URBAN_VILLAGE_PREFIX];
}

export function isCityPresent(text) {
	const trimmedName = text.trim();
	return isPrefixPresent(trimmedName, CITY_PREFIX);
}

export function isVillageAPresent(text) {
	const trimmedName = text.trim();
	return isPrefixPresent(trimmedName, VILLAGE_A_PREFIX);
}

export function isVillageBPresent(text) {
	const trimmedName = text.trim();
	return isPrefixPresent(trimmedName, VILLAGE_B_PREFIX);
}

export function isVillageCPresent(text) {
	const trimmedName = text.trim();
	return isPrefixPresent(trimmedName, VILLAGE_C_PREFIX);
}

export function isUrbanVillagePresent(text) {
	const trimmedName = text.trim();
	return isPrefixPresent(trimmedName, URBAN_VILLAGE_PREFIX);
}

export function isCityTypeMissing(text) {
	return !isCityPresent(text) && !isVillageAPresent(text) && !isVillageBPresent(text) && !isVillageCPresent(text) && !isUrbanVillagePresent(text);
}

export function getCityTrimmedName(cityName) {
	let trimmedName = trimSpaces(cityName);
	const allPrefixes = _allPrefixes();
	allPrefixes.forEach((prefix) => {
		trimmedName = trimmedName.replace(prefix, '');
	});
	trimmedName = trimmedName.trim();
	return trimmedName;
}

export function getCityTypeAndTrimmedName(cityName) {

	let typeId = 1;
	if (isCityPresent(cityName)) {
		typeId = 1;
	}
	else if (isVillageAPresent(cityName)) {
		typeId = 2;
	}
	else if(isUrbanVillagePresent(cityName)) {
		typeId = 3;
	}
	else if (isVillageBPresent(cityName)) {
		typeId = 4;
	}
	else if (isVillageCPresent(cityName)) {
		typeId = 5;
	}

	const trimmedName = getCityTrimmedName(cityName);
	return [trimmedName, typeId];
}

export function removeEmoji(text) {
	return text.replace(new RegExp(EMOJI_RANGES.join('|'), 'g'), '');
}
