import {
	SET_FULL_URL,
  SET_VK_PARAMS,
  SET_VK_APP_SETTINGS,
  SET_ACCESS_TOKEN
} from '../types/vk';

export function setFullURL(vk_full_url){
	return { type: SET_FULL_URL, vk_full_url };
}

export function setVKParams(vk_params) {
  return { type: SET_VK_PARAMS, vk_params };
}

export function setVKSettings(vk_settings) {
	return { type: SET_VK_APP_SETTINGS, vk_settings };
}

export function setAccessToken(access_token){
	return { type: SET_ACCESS_TOKEN, access_token };
}