import qs from 'querystring';
import * as APIConstants from '../constants/API';
import * as ErrorCodes from '../constants/errors/APIServiceErrors';

import fetcher from './Fetcher';
import{ 
	getCityTypeAndTrimmedName,
	removeEmoji
} from './texts.service';
import{ composeScreenError } from './errors.service';


export default class APIService {

	constructor(props={}) {
		this.props = props;
	}

	updateProps(props={}) {
		this.props = props;
	}

	fetchContests(shouldFetchActive) {

		const url = shouldFetchActive ? APIConstants.CONTEST_GET_ACTIVE_URL : APIConstants.CONTEST_GET_INACTIVE_URL;

		return new Promise((resolve, reject) => {
			fetcher.get(url)
			.then((response) => {

				if (response.ok) {
					const json = response.data;

					const contests = json.contests;
					if (contests === null || contests === undefined) {
						const error = composeScreenError(
							'Произошла ошибка',
							'contests is null or undefined',
							ErrorCodes.FETCH_CONTESTS_ARRAY_IS_NULL
						);
						return reject(error);
					}

					if (!!contests && contests.constructor !== Array) {
						const error = composeScreenError(
							'Произошла ошибка',
							'contests is not an array',
							ErrorCodes.FETCH_CONTESTS_IS_NOT_ARRAY
						);
						return reject(error);
					}

					return resolve(contests);
				}
				else {
					const error = composeScreenError(
						'Произошла ошибка', 
						'Response status is not ok',
						ErrorCodes.FETCH_CONTESTS_BAD_RESPONSE
					);
					return reject(error);
				}
			})
			.catch((ex) => {
				const error = composeScreenError(
					'Произошла ошибка',
					ex,
					ErrorCodes.FETCH_CONTESTS_PARSE
				);
				return reject(error);
			});
		});
	}

	fetchContestData(contestId) {
		const url = `${APIConstants.CONTEST_GET_ONE_URL}/${contestId}`;
		return new Promise((resolve, reject) => {
			fetcher.get(url)
			.then((response) => {
				if (response.ok) {
					const json = response.data;
					const contest = json.contest;

					console.log("fetchContestData", { contest:contest });

					if (!!contest && typeof contest === 'object') {
						return resolve(contest);
					}
					else {
						const error = composeScreenError(
							'Произошла ошибка', 
							'Response status is not ok',
							ErrorCodes.FETCH_CONTEST_NOT_OBJECT
						);
						return reject(error);
					}
				}
				else {
					const error = composeScreenError(
						'Произошла ошибка', 
						'Response status is not ok',
						ErrorCodes.FETCH_CONTEST_BAD_RESPONSE
					);
					return reject(error);
				}
			})
			.catch((ex) => {
				const error = composeScreenError(
					'Произошла ошибка',
					ex,
					ErrorCodes.FETCH_CONTEST_PARSE
				);
				return reject(error);
			});
		});
	}

	placeOrder(params, vk_params) {
		const {
			contestId,

			numberOfParticipants,
			participantsNamesArray,
			participantsDescriptionsArray,

			federalSubject,
			city,
			schoolType,
			schoolName,
			workName,
			workDescription,

			photosData
		}=params;

		// Extract city info
		const [trimmedCity, cityTypeId] = getCityTypeAndTrimmedName(city);

		// Sanitize description
		const work_description = removeEmoji(workDescription);

		const url = APIConstants.ORDERS_URL;
		const data = {
			contest_id: contestId,

			number_of_participants: numberOfParticipants,
			participants_names_array: participantsNamesArray,
			participants_descriptions_array: participantsDescriptionsArray,

			federal_subject: federalSubject,
			city_name: trimmedCity,
			city_type_id: cityTypeId,

			school_type: schoolType,
			school_name: schoolName,

			work_name: workName,
			work_description,

			use_new_vk_photos_data: true,
			vk_photos_data: photosData,

			use_new_vk_scheme: true,
			...vk_params
		};

		return new Promise((resolve, reject) => {
			fetcher.post(url, data)
			.then((response) => {
				if (response.ok) {
					const json = response.data;
					return resolve(json);
				}
				else {
					const error = composeScreenError(
						'Произошла ошибка', 
						'Response status is not ok',
						ErrorCodes.PLACE_ORDER_BAD_RESPONSE
					);
					return reject(error);
				}
			})
			.catch((ex) => {
				const error = composeScreenError(
					'Произошла ошибка',
					ex,
					ErrorCodes.PLACE_ORDER_PARSE
				);
				return reject(error);
			});
		});
	}

	fetchOrderStatus(orderId) {

		const url = `${APIConstants.ORDERS_URL}/${orderId}/status`;

		return new Promise((resolve, reject) => {
			fetcher.get(url)
			.then((response) => {

				if (response.ok) {
					const json = response.data;
					const isFilled = json.order.is_filled;
					return resolve(isFilled);
				}
				else {
					const error = composeScreenError(
						'Произошла ошибка', 
						'Response status is not ok',
						ErrorCodes.FETCH_ORDER_STATUS_BAD_RESPONSE
					);
					return reject(error);
				}
			})
			.catch((ex) => {
				const error = composeScreenError(
					'Произошла ошибка',
					ex,
					ErrorCodes.FETCH_ORDER_STATUS_PARSE
				);
				return reject(error);
			});
		});
	}

	fetchOrders(vkData) {

		const vkParams = { 
			use_new_vk_scheme: 1,
			vk_user_id: vkData.params.vk_user_id,
			vk_app_full_url: vkData.fullURL
		};
		const query = qs.stringify(vkParams);
		const urlWithQuery = `${APIConstants.ORDERS_URL}?${query}`;

		return new Promise((resolve, reject) => {
			fetcher.get(urlWithQuery)
			.then((response) => {
				if (response.ok) {
					const json = response.data;
					const orders = json.orders;

					if (orders === null || orders === undefined || !orders) {
						const error = composeScreenError(
							'Произошла ошибка', 
							'Response status is not ok',
							ErrorCodes.FETCH_ORDERS_ARRAY_IS_NULL
						);
						return reject(error);
					}

					if (!!orders && orders.constructor !== Array) {
						const error = composeScreenError(
							'Произошла ошибка',
							'contests is not an array',
							ErrorCodes.FETCH_ORDERS_IS_NOT_ARRAY
						);
						return reject(error);
					}

					return resolve(orders);
				}
				else {
					const error = composeScreenError(
						'Произошла ошибка', 
						'Response status is not ok',
						ErrorCodes.FETCH_ORDERS_BAD_RESPONSE
					);
					return reject(error);
				}
			})
			.catch((ex) => {
				const error = composeScreenError(
					'Произошла ошибка',
					ex,
					ErrorCodes.FETCH_ORDERS_PARSE
				);
				return reject(error);
			});
		});
	}

	fetchNumberOfUnfilledUserOrders(vkData) {

		const vkParams = { 
			vk_app_full_url: vkData.fullURL,
			vk_user_id: vkData.params.vk_user_id,
			use_new_vk_scheme: 1,
		};

		const query = qs.stringify(vkParams);
		const urlWithQuery = `${APIConstants.ORDERS_URL}?${query}`;

		return new Promise((resolve, reject) => {
			fetcher.get(urlWithQuery)
			.then((response) => {
				if (response.ok) {
					const json = response.data;
					const numberOfUnfilledOrders = json.numberOfUnfilledOrders;
					return resolve(numberOfUnfilledOrders);
				}
				else {
					const error = composeScreenError(
						'Произошла ошибка', 
						'Response status is not ok',
						ErrorCodes.FETCH_NUMBER_OF_UNFILLED_USER_ORDERS_BAD_RESPONSE
					);
					return reject(error);
				}
			})
			.catch((ex) => {
				const error = composeScreenError(
					'Произошла ошибка',
					ex,
					ErrorCodes.FETCH_NUMBER_OF_UNFILLED_USER_ORDERS_PARSE
				);
				return reject(error);
			});
		});
	}
}
