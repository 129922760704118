import React from "react";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as Routes from '../constants/Routes';

import * as VKAllActions from '../redux/actions/vk';
import * as URLParser from '../services/URLParser';
import VKService, { subscribeToVKEvents } from '../services/vk.service';

import { Switch, Route } from 'react-router-dom';
import TopBar from './TopBar';
import HomeScreen from '../screens/HomeScreen';
import MyOrdersScreen from '../screens/MyOrdersScreen';
import BecomeMemberScreen from '../screens/BecomeMemberScreen';
import ContestOverviewScreen from '../screens/ContestOverviewScreen';
import ParticipateScreen from '../screens/ParticipateScreen';
import PaymentScreen from '../screens/PaymentScreen';
import PaymentCreditedScreen from '../screens/PaymentCreditedScreen';

class AppComponent extends React.Component {

	constructor(props){
		super(props);

		this.state={}
	}

	// _handleVKEvent(e){
	// 	const{ 
	// 		VKActions,
	// 		vk_settings
	// 	}=this.props;

	// 	switch(e.type){
	// 		case "VKWebAppJoinGroupResult":{
	// 			console.log({ e });

	// 			if (!!e.data.error_type)
	// 				break;

	// 			if (!!e.data.result.true){
	// 				const vk_viewer_group_role = 'member';
	// 				VKActions.setVKSettings({ ...vk_settings, vk_viewer_group_role });
	// 			}
	// 			break;
	// 		}
	// 		default:
	// 			break;
	// 	}
	// }

	componentWillMount(){
		this._parseVKUrl();
		// subscribeToVKEvents(this._handleVKEvent.bind(this));
	}

	async componentDidMount() {
		this.vkService = new VKService({ ...this.props.vk });
		// this.vkService.addSettingsCallback((newAPISettings)=>{
		// 	this._setVKAPISettings(newAPISettings);
		// });

		
		try{
			const data = await this.vkService.askForToken();
			if (!!data.access_token) {
				const accessToken = data.access_token;
				this.props.VKActions.setAccessToken(accessToken);
			}
		}
		catch(error){
			console.error("ContestOverviewScreen vk service error: ", error);
		}
	}

	// componentWillReceiveProps(nextProps){
	// 	this.vkService.updateProps({ ...nextProps.vk_settings });
	// }

	_setVKAPISettings(settings){
		const{ VKActions }=this.props;
		VKActions.setAPISettings(settings);
	}

	_parseVKUrl(){
		const up = URLParser.getJsonFromUrl();

		if (!!up.vk_app_id && !!up.vk_user_id && !!up.sign){
			const vkAppFullUrl = URLParser.getSearchQuery();

			const vk_params = {
				vk_user_id: up.vk_user_id,
				vk_app_id: up.vk_app_id,
				vk_is_app_user: up.vk_is_app_user,
				vk_are_notifications_enabled: up.vk_are_notifications_enabled,
				vk_language: up.vk_language,
				vk_access_token_settings: up.vk_access_token_settings,
				vk_platform: up.vk_platform,
				vk_sign: up.sign
			};

			const vk_settings = {
				vk_is_app_user: up.vk_is_app_user,
				vk_are_notifications_enabled: up.vk_are_notifications_enabled,
				vk_viewer_group_role: up.vk_viewer_group_role,
				vk_access_token_settings: up.vk_access_token_settings,
				vk_group_id: up.vk_group_id
			};

			this.props.VKActions.setFullURL(vkAppFullUrl);
			this.props.VKActions.setVKParams(vk_params);
			this.props.VKActions.setVKSettings(vk_settings);
		}
		else{
			console.error("Main.js error: No VK URL arguments");
		}
	}

	render(){

		return(
			<div>
				<TopBar 
					windowWidth={ this.props.windowWidth }
					numberOfUnfilledOrders={ this.props.numberOfUnfilledOrders }
				/>
				<Switch>
					<Route exact path={ Routes.MAIN } component={ HomeScreen }/>
					<Route exact path={ Routes.MY_ORDERS } component={ MyOrdersScreen }/>
					<Route exact path={ Routes.BECOME_MEMBER } component={ BecomeMemberScreen }/>
					<Route exact path={ `${Routes.CONTEST}/:id` } component={ ContestOverviewScreen }/>
					<Route exact path={ `${Routes.PARTICIPATE}/:id` } component={ ParticipateScreen }/>
				</Switch>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		...state.windowReducer,
		vk: state.vk,
		numberOfUnfilledOrders: state.orders.numberOfUnfilledOrders
	};
}

function mapDispatchToProps(dispatch) {
	return {
		VKActions: bindActionCreators(VKAllActions, dispatch)
	};
}

const AppComponentConnected = connect(mapStateToProps, mapDispatchToProps)(AppComponent);


export default function Main(props){
	return (
		<main style={{ overflowX: "hidden" }}>
			<Switch>
				<Route exact path={ Routes.PAYMENT_CREDITED } component={ PaymentCreditedScreen }/>
				<Route exact path={ Routes.PAYMENT } component={ PaymentScreen }/>

				<Route path={ Routes.MAIN } component={ AppComponentConnected }/>
			</Switch>
		</main>
	);
}