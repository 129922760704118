import React, { Component } from "react";
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from 'material-ui/TextField';
import Paper from 'material-ui/Paper';
import { MenuItem } from 'material-ui/Menu';
import { withStyles } from 'material-ui/styles';

const styles = theme => ({
	container: {
		flexGrow: 1,
		position: 'relative',
		width: '100%',
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit * 3,
		left: 0,
		right: 0,
	},
	suggestion: {
		display: 'block',
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none',
	},

	menuItem:{
		display: 'block',
		zIndex: 20000,
		backgroundColor: '#fff'
	}
});

function renderInput(inputProps, required, errorText) {
	const { classes, ref, ...other } = inputProps;
	let isError = (errorText !== undefined && errorText !== "");

	return (
		<TextField
			fullWidth
			inputRef={ref}
			required={required}
			InputProps={{
				classes: {
					input: classes.input,
				},
				...other,
			}}
			error={isError}
			helperText={errorText}
			style={{overflow: 'hidden'}}
		/>
	);
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
	const matches = match(suggestion.label, query);
	const parts = parse(suggestion.label, matches);

	return (
		<MenuItem selected={isHighlighted} component="div" >
			<div>
				{parts.map((part, index) => {
					return part.highlight ? (
						<span key={ String(index) } style={{ fontWeight: 500 }}>
							{ part.text }
						</span>
					) : (
						<strong key={ String(index) } style={{ fontWeight: 300 }}>
							{ part.text }
						</strong>
					);
				})}
			</div>
		</MenuItem>
	);
}

function renderSuggestionsContainer(options) {
	const { containerProps, children } = options;

	return (
		<Paper {...containerProps} square style={{ zIndex: 20000}}>
			{children}
		</Paper>
	);
}

function getSuggestionValue(suggestion) {
	return suggestion.label;
}

function getSuggestions(suggestions, value) {

	const inputValue = value.trim().toLowerCase();
	const inputLength = inputValue.length;
	let count = 0;

	return inputLength === 0 ? [] : suggestions.filter(suggestion => {
		let lowerCaseSuggestion = suggestion.label.toLowerCase();
		const keep = count < 5 && lowerCaseSuggestion.includes(inputValue);

		if (keep) {
			count += 1;
		}

		return keep;
	});
}

class AutoSuggest extends Component {

	constructor(props){
		super(props);

		const {
			inputRef,
			required
		}=props;

		this.state = {
			value: props.value,
			displayedSuggestions: []
		};

		this.inputRef = {
			value:this.state.value,
			required: required
		};
		inputRef(this.inputRef);
	}

	handleSuggestionsFetchRequested = ({ value }) => {
		const{
			suggestions
		}=this.props;

		let displayedSuggestions = getSuggestions(suggestions, value);

		this.setState({ displayedSuggestions });
	};

	handleSuggestionsClearRequested = () => {
		let displayedSuggestions = [];
		this.setState({ displayedSuggestions });
	};

	handleChange = (event, { newValue }) => {
		const { 
			onChange,
			required,
			inputRef,
		}=this.props;

		let value = newValue;

		this.inputRef = {
			value:value,
			required: required
		};
		inputRef(this.inputRef);

		onChange(value);
		this.setState({ value });
	};

	render() {
		const { 
			classes,
			placeholder,
			required,
			errorText
		}=this.props;

		// console.log(errorText);

		return (
			<Autosuggest
				theme={{
					container: classes.container,
					suggestionsContainerOpen: classes.suggestionsContainerOpen,
					suggestionsList: classes.suggestionsList,
					suggestion: classes.suggestion,
				}}
				renderInputComponent={ (inputProps) => renderInput(inputProps, required, errorText) }
				suggestions={ this.state.displayedSuggestions }
				onSuggestionsFetchRequested={ this.handleSuggestionsFetchRequested }
				onSuggestionsClearRequested={ this.handleSuggestionsClearRequested }
				renderSuggestionsContainer={ renderSuggestionsContainer }
				getSuggestionValue={ getSuggestionValue }
				renderSuggestion={ renderSuggestion }
				inputProps={{
					classes,
					placeholder: placeholder,
					value: this.state.value,
					onChange: this.handleChange,
				}}
			/>
		);
	}
}

AutoSuggest.propTypes = {
	placeholder: PropTypes.string,
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutoSuggest);
