/* Fetcher */
import axios from "axios";


export default {
	get:_get,
	post:_post
};

function _get(url, queryParams=null) {
	let fullURL = `${url}`;
	// if (queryParams !== null){

	// }
	
	return new Promise((resolve, reject)=>{
		axios.get(fullURL)
		.then((res)=>{
			const isOK = res.statusText === "OK";
			const response = {...res, ok:isOK};
			response.data = response.data.data;
			return resolve(response);
		})
		.catch((ex)=>reject(ex));
	});
}

function _post(url, params=null) {
	return new Promise((resolve, reject)=>{
		axios.post(url, params)
		.then((res)=>{
			const isOK = res.statusText === "OK";
			const response = {...res, ok:isOK};
			response.data = response.data.data;
			return resolve(response);
		})
		.catch((ex)=>reject(ex));
	});
}
