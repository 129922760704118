import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ContestsActions from '../redux/actions/contests';
import * as OrdersActions from '../redux/actions/orders';

import APIService from '../services/APIService';
import {
	METRIKA_GOALS,
	reachGoal
} from '../services/Analytics';

import ContestList from '../components/ContestList';
import Snackbar from 'material-ui/Snackbar';

const styles = {
	screenContainer:{
		paddingTop: 70,
		backgroundColor: '#EDEEF0'
	},

	controlContainer:{
		width: 265,
		padding: 12,
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#FF5F26'
	},

	listTitle:{
		fontSize:18,
		textAlign: 'center'
	}
}

class HomeScreen extends React.Component {

	constructor(props){
		super(props);

		this.state={
			isErrorOpen: false,
			errorText: ''
		}

		this.API = new APIService();
	}

	componentDidMount() {
		this._fetchUserOrders();
	}

	_showError(errText, errorCode){

		const errorText = `${errText}. Код: ${errorCode}`;

		const isErrorOpen = true;
		this.setState({ 
			isErrorOpen,
			errorText,
		});
	}

	/* Network */
	_fetchUserOrders(){
		this.API.fetchNumberOfUnfilledUserOrders({ ...this.props.vk })
		.then((numberOfUnfilledOrders) => {
			this.props.ordersActions.setNumberOfUnfilledOrders(numberOfUnfilledOrders);
		})
		.catch((error) => {
			console.error(error.logMSG);
			this._showError(error.displayMSG, error.code);
		});
	}

	/* Interaction */
	_handleErrorCloseRequest(){
		const isErrorOpen = false;
		this.setState({isErrorOpen});
	}

	_contestWasChosen(chosenContest){
		this.props.chosenContestActions.setContestData(chosenContest);

		// Analytics
		reachGoal(METRIKA_GOALS.PARTICIPATE.ORDER.FILLED);
	}

	/* Render */
	render() {
		const{
			windowWidth,
			windowHeight
		}=this.props;

		const {
			isErrorOpen,
			errorText
		}=this.state;

		const containerStyle = windowWidth < 500 ? { ...styles.screenContainer, textAlign: 'center' } : { ...styles.screenContainer};

		return (
			<div>
				<div style={ containerStyle }>
					<p style={ styles.listTitle }>Список текущих конкурсов:</p>
					<ContestList
						windowWidth={ windowWidth }
						windowHeight={ windowHeight }
						shouldFetchActive={ true }
						onContestClick={ this._contestWasChosen.bind(this) }
						onError={(error) => {
							console.error(error.logMSG);
							this._showError(error.displayMSG, error.code);
						}}
					/>
				</div>
				<Snackbar
					open={ isErrorOpen }
					message={ errorText }
					autoHideDuration={ 3500 }
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		...state.windowReducer,
		vk: state.vk,
		chosenContest: state.contest
	};
}

function mapDispatchToProps(dispatch) {
	return {
		ordersActions: bindActionCreators(OrdersActions, dispatch),
		chosenContestActions: bindActionCreators(ContestsActions, dispatch)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HomeScreen);
