import React from "react";

import ParticipateButton from './ParticipateButton';

const styles = {
	container:{
		position: 'fixed',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		bottom: -80,
		width: '100%',
		paddingTop: 2,
		paddingBottom: 84,
		textAlign: 'center',
		backgroundColor: '#EDEEF0',

		boxShadow:'0px -2px 7px 0px #EDEEF0',
	}
};

export default function ParticipateFixedBlock(props){
	const{
		onClick,
		isFixed,
		disabled,
		style
	}=props;

	const containerStyle = isFixed ? { ...styles.container, ...style } : 
												{ ...styles.container, position: 'relative', bottom: 'none', paddingBottom: 'none', ...style };

	return(
		<div style={containerStyle}>
			<ParticipateButton
				onClick={onClick}
				disabled={disabled}
			/>
		</div>
	);
}