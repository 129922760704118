/* Error codes from 600 to ... */

export const FETCH_CONTESTS_PARSE = 610;
export const FETCH_CONTESTS_BAD_RESPONSE = 614;
export const FETCH_CONTESTS_ARRAY_IS_NULL = 616;
export const FETCH_CONTESTS_IS_NOT_ARRAY = 617;

export const FETCH_NUMBER_OF_UNFILLED_USER_ORDERS_PARSE = 620;
export const FETCH_NUMBER_OF_UNFILLED_USER_ORDERS_BAD_RESPONSE = 622;

export const FETCH_ORDER_STATUS_PARSE = 624;
export const FETCH_ORDER_STATUS_BAD_RESPONSE = 626;

export const FETCH_CONTEST_PARSE = 630;
export const FETCH_CONTEST_BAD_RESPONSE = 634;
export const FETCH_CONTEST_NOT_OBJECT = 636;

export const FETCH_ORDERS_PARSE = 640;
export const FETCH_ORDERS_BAD_RESPONSE = 644;
export const FETCH_ORDERS_ARRAY_IS_NULL = 646;
export const FETCH_ORDERS_IS_NOT_ARRAY = 647;

export const PLACE_ORDER_PARSE = 720;
export const PLACE_ORDER_BAD_RESPONSE = 724;