import React from "react";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ContestsActions from '../redux/actions/contests';
import * as VKAllActions from '../redux/actions/vk';
import * as Routes from '../constants/Routes';

import APIService from '../services/APIService';
import VKService from '../services/vk.service';
import { formatDate } from '../services/dates.service';
import { breakTextIntoParagraphs } from '../services/texts.service';
import {
	METRIKA_GOALS,
	reachGoal
} from '../services/Analytics';

import OverviewTitle from '../components/contest/OverviewTitle';
import ContestMetaBlock from '../components/ContestMetaBlock';
import DescriptionBlock from '../components/contest/DescriptionBlock';
import DownloadConditionsButton from '../components/contest/DownloadConditionsButton';
import ParticipateFixedBlock from '../components/contest/ParticipateFixedBlock';
import Snackbar from 'material-ui/Snackbar';

import styles from '../components/overview/styles';


class ContestOverviewScreen extends React.Component {

	constructor(props){
		super(props);

		this.state={
			didFetchContest: false,
			isFetchError: false,

			id: '',
			name: '',
			imgUrl: '',
			description: '',
			conditions: '',
			vkGroupId: null,
			conditionsURL: '',
			startDate: '',
			endDate: '',

			errorText: '',
			isErrorOpen: false
		}

		this.API = new APIService();
		this.vkService = new VKService({ ...props.vk });
	}

	_proceedToParticipate(){
		// Analytics
		reachGoal(METRIKA_GOALS.OVERVIEW.PARTICIPATE.CLICK);

		const contestId = this.state.id;
		const route = `${Routes.PARTICIPATE}/${contestId}`;
		this.props.history.push(route);
	}

	_saveContestData(contest){
		const didFetchContest = true;
		const id = contest.id;
		const name = contest.name;
		const imgUrl = contest.img_url;
		const description = contest.description;
		const conditions = contest.conditions;
		const conditionsURL = contest.conditions_url;
		// VK Group info
		const vkGroupId = contest.vk_group_id;
		// Parse dates
		const startDate = formatDate(contest.start_date);
		const endDate = formatDate(contest.end_date);

		// Save contest data globally
		this.props.chosenContestActions.setContestData(contest);

		// Save contest data locally
		this.setState({ 
			didFetchContest,
			id,
			name,
			imgUrl,
			description,
			conditions,
			conditionsURL,
			vkGroupId,
			startDate,
			endDate
		});
	}

	componentDidMount() {
		// Check if contest was transfered
		const{
			contest,
			match
		}=this.props;

		// Get current contest id
		const id = match.params.id;

		if (!contest.id || contest.id !== id){
			// Fetch contest data
			if (id && id !== ''){
				this._fetchContestData(id);
			}
		}
		else{
			this._saveContestData(contest);
		}
	}

	componentWillReceiveProps(nextProps){
		this.vkService.updateProps({ ...nextProps.vk });
	}

	async _fetchContestData(id){
		try{
			const contest = await this.API.fetchContestData(id);
			this._saveContestData(contest)
		}
		catch(error){
			this._showError(error.displayMSG, error.code);
			return console.error(error.logMSG);
		}
	}

	/* Inputs */
	async _handleParticipateButtonAction(){

		// If User is member of community & allowed photos
		if (this.vkService.isGroupMember() && this.vkService.checkPhotoUploadPermission()){
			return this._proceedToParticipate();
		}

		if (!this.vkService.isGroupMember()){
			try{
				const data = await this.vkService.askToJoinGroup();
				if (data.result === true){
					const vk_viewer_group_role = 'member';
					this.props.VKActions.setVKSettings({ ...this.props.vk_settings, vk_viewer_group_role });
				}
			}
			catch(error){
				console.error("ContestOverviewScreen vk service error: ", error);
			}
			return;
		}

		if (!this.vkService.checkPhotoUploadPermission()){
			try{
				const data = await this.vkService.askForPhotoUploadPermission();
				if (!!data.access_token){
					const accessToken = data.access_token;
					this.props.VKActions.setAccessToken(accessToken);

					const scope = data.scope;
					this.props.VKActions.setVKSettings({ ...this.props.vk_settings, vk_access_token_settings:scope });
				}
			}
			catch(error){
				console.error("ContestOverviewScreen vk service error: ", error);
			}
		}
	}

	/* Errors */
	_showError(errText, errorCode){

		const errorText = `${errText}. Код: ${errorCode}`;

		const isErrorOpen = true;
		const isLoadingOrder = false;
		this.setState({ 
			isErrorOpen,
			errorText,
			isLoadingOrder
		});
	}

	_handleErrorCloseRequest(){
		const isErrorOpen = false;
		this.setState({ isErrorOpen });
	}

	/* Visuals */
	_getStyleForContentFrame(windowWidth){
		// For mobiles
		if (windowWidth < 500){
			return {
				display: "block",
				padding: "0 10px 0 10px",
			};
		}

		return {
			display: "block",
			width: '90%',
			maxWidth: 650,
			marginLeft: 'auto',
			marginRight: 'auto',
		};
	}

	/* Render */

	render() {
		const{ windowWidth }=this.props;
		const {
			didFetchContest,

			id,
			name,

			imgUrl,
			vkGroupId,
			description,
			conditions,
			conditionsURL,

			startDate, 
			endDate,

			isErrorOpen,
			errorText
		}=this.state;

		const containerStyle = windowWidth < 500 ? styles.mobileContainer : styles.desktopContainer;
		const contentFrameStyle = this._getStyleForContentFrame(windowWidth);

		return (
			<div>
				<div style={ containerStyle }>
					<div style={ contentFrameStyle }>
						<OverviewTitle h1 windowWidth={ windowWidth } text={ name }/>
						<div style={ styles.contentBlock }>
							<ContestMetaBlock
								windowWidth={ windowWidth }
								imgUrl={ imgUrl }
								startDate={ startDate }
								endDate={ endDate }
							/>
						</div>
						{ windowWidth < 500 ?
							<ParticipateFixedBlock
								onClick={ () => this._handleParticipateButtonAction(id) }
								isFixed={ false }
								disabled={ !didFetchContest }
								style={{marginBottom:20}}
							/>
							:
							null
						}
						<DescriptionBlock
							windowWidth={ windowWidth }
							description={ description }
							vkGroupId={ vkGroupId }
						/>
						<div style={ styles.contentBlock }>
							<OverviewTitle h2 windowWidth={ windowWidth } text="Положение"/>
							 <DownloadConditionsButton
							 	conditionsURL={ conditionsURL }
							 />
							<div style={ styles.description }>
								{ breakTextIntoParagraphs(conditions) }
							</div>
							<div style={{ ...styles.description, textAlign:'center' }}>
								<p>Нажимая кнопку "Участвовать", вы соглашаетесь с данным положением.</p>
							</div>
						</div>
						{ windowWidth > 500 ? <div style={styles.bottomBlock}/> : null }
					</div>
					<ParticipateFixedBlock
						onClick={ () => this._handleParticipateButtonAction(id) }
						isFixed={ windowWidth > 500 }
						disabled={ !didFetchContest }
					/>
				</div>
				<Snackbar
					open={ isErrorOpen }
					message={ errorText }
					autoHideDuration={ 3500 }
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		...state.windowReducer,
		contest: state.contests,
		vk: state.vk
	};
}

function mapDispatchToProps(dispatch) {
	return {
		chosenContestActions: bindActionCreators(ContestsActions, dispatch),
		VKActions: bindActionCreators(VKAllActions, dispatch)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ContestOverviewScreen);
