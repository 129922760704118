import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ORDER_STATUS from '../constants/OrderStatus';
import * as OrdersActions from '../redux/actions/orders';

import APIService from '../services/APIService';
import { composeWallUrl } from '../services/vk.service';

import OrderBlock from '../components/orders/OrderBlock';
import { CircularProgress } from 'material-ui/Progress';
import Snackbar from 'material-ui/Snackbar';

const styles = {
	desktopContainer:{
		boxSizing: 'border-box',
		overflowY: 'scroll',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		margin: 0,
		padding: '70px 0 10px 0px',
		textAlign: 'center'
	},

	mobileContainer:{
		padding: '60px 0 10px 0px',
		textAlign: 'center'
	},

	contentBlock:{
		display:'block', 
		float:'left', 
		width:'100%',
		marginBottom: 20,
		backgroundColor: '#fff',
		boxShadow:'0px 1px 6px 0px rgba(0,0,0,0.21)'
	}
}

function getStyleForContentFrame(windowWidth){
		// For mobiles
		if (windowWidth < 500){
			return {
				display: "block",
				padding: "0 10px 0 10px",
			};
		}

		return {
			display: "block",
			width: '90%',
			maxWidth: 650,
			marginLeft: 'auto',
			marginRight: 'auto',
		};
	}

class MyOrdersScreen extends React.Component {

	constructor(props){
		super(props);

		this.state = {
			orders: [],
			isFetching: true,

			isErrorOpen: false,
			errorText: ""
		}

		this.API = new APIService();
		this.paymentCheckTimer = null;
	}

	_showError(errText, errorCode){
		const errorText = `${errText}. Код: ${errorCode}`;

		const isErrorOpen = true;
		this.setState({ 
			isErrorOpen,
			errorText,
		});
	}

	componentWillUnmount(){
		this._stopOrderStatusFetchTimer();
	}

	componentDidMount() {
		this._fetchOrders();
	}

	_countUnfilledOrders(newOrders){
		const{
			ordersActions
		}=this.props;

		let count = 0;
		newOrders.forEach((order)=>{
			const isFilled = Boolean(order.is_filled);
			if (Boolean(order.contest_is_active) && isFilled === false)
				count++;
		});

		ordersActions.setNumberOfUnfilledOrders(count);
	}

	_startOrderStatusFetchTimer(orderId){
		this.paymentCheckTimer = setInterval( ()=>this._fetchOrderStatus(orderId), 420 );
	}

	_stopOrderStatusFetchTimer(){
		clearInterval(this.paymentCheckTimer);
	}

	_setOrderAsFilled(orderId){
		const orders = [ ...this.state.orders ];
		for(let i=0; i < orders.length; i++){
			const order = orders[i];
			if (order.id === orderId){
				orders[i].is_filled = true;
			}
		}
		this.setState({ orders });
	}

	/* Network */
	_fetchOrders(){
		this.API.fetchOrders({ ...this.props.vk })
		.then((orders)=>{
			console.log("MyOrdersScreen:", { orders });

			this._countUnfilledOrders(orders);
			const isFetching = false;
			this.setState({
				orders,
				isFetching
			})
		})
		.catch((error)=>{
			this._showError(error.displayMSG, error.code);
			return console.error(error.logMSG);
		});
	}

	_fetchOrderStatus(orderId){
		this.API.fetchOrderStatus(orderId)
		.then((isFilled)=>{
			if (isFilled){
				this._stopOrderStatusFetchTimer();
				this._setOrderAsFilled(orderId);
			}
		})
		.catch((error)=>{
			this._showError(error.displayMSG, error.code);
			return console.error(error.logMSG);
		});
	}

	/* Interactions */
	_handlePaymentButtonClick(orderId){
		this._startOrderStatusFetchTimer(orderId);
	}

	/* Render */
	_renderUserOrders(windowWidth, orders){

		const orderBlocks = [];

		orders.forEach((order, index)=>{

			console.log({ order });
 
			const postPhotos = order.post_photos;

			if (!!postPhotos && postPhotos.length > 0){
				const photoUrl = postPhotos[0].vk_photo_130;
				const isFilled = Boolean(order.is_filled);

				const status = order.order_status;
				const postLink = status > ORDER_STATUS.RECIEVED ? composeWallUrl(order.vk_group_id, order.vk_post_id) : null;

				orderBlocks.push(
					<div key={ index } style={ styles.contentBlock }>
						<OrderBlock
							orderId={ order.id }
							price={ order.price_rub }
							imgUrl={ photoUrl }
							contestId={ order.contest_id }
							contestName={ order.contest_name }
							startDate={ order.start_date }
							endDate={ order.end_date }
							isContestActive={ order.contest_is_active }
							workName={ order.work_name }
							windowWidth={ windowWidth }
							isFilled={ isFilled }
							status={ status }
							postLink={ postLink }
							diploma={ order.diploma }
							onPaymentButtonClick={(orderId)=>{ this._handlePaymentButtonClick(orderId) }}
						/>
					</div>
				);
			}
		});

		return orderBlocks;
	}

	_renderOrdersBlock(windowWidth, isFetching, orders){

		if (isFetching){
			return(
				<p/>
			);
		}

		const contentFrameStyle = getStyleForContentFrame(windowWidth);

		return(
			<div style={ contentFrameStyle }>
				{ this._renderUserOrders(windowWidth, orders) }
			</div>
		);
	}

	_renderActivity(isFetching){
		if (isFetching){
			return(
				<CircularProgress 
					style={{ width: 80 }}
				/>
			);
		}

		return null;
	}

	render() {
		const{
			windowWidth
		}=this.props;

		const{
			orders,
			isFetching,

			isErrorOpen,
			errorText
		}=this.state;

		const containerStyle = windowWidth < 500 ? styles.mobileContainer : styles.desktopContainer;

		return (
			<div style={ containerStyle }>
				<p style={{ fontSize:18 }}>
					Список ваших работ на конкурсах:
				</p>
				{ this._renderActivity(isFetching) }
				{ this._renderOrdersBlock(windowWidth, isFetching, orders) }

				<Snackbar
					open={ isErrorOpen }
					message={ errorText }
					autoHideDuration={ 3500 }
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		...state.windowReducer,
		vk: state.vk
	};
}

function mapDispatchToProps(dispatch) {
	return {
		ordersActions: bindActionCreators(OrdersActions, dispatch)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MyOrdersScreen);
