
module.exports = {
	setItem:_setItem,
	getItem:_getItem,
	unwrapSavedFieldValue:_unwrapSavedFieldValue,
	clearSavedValues:_clearSavedValues
};

function _setItem(key, value) {
	localStorage.setItem(key, value);
}

function _getItem(textFieldKey, placeHolder=null) {
	const value = localStorage.getItem(textFieldKey);

	if (placeHolder !== null && (value === null || value === undefined))
		return placeHolder;

	return !!value ? value : '';
}

function _unwrapSavedFieldValue(textFieldKey, minValue=null) {
	const value = _getItem(textFieldKey);
	if (minValue !== null) {
		if (isNaN(value) || value === null || value === undefined || value === "") {
			return minValue;
		}

		if (parseInt(value) < minValue)
			return minValue;
		else
			return parseInt(value);
	}

	return !!value ? value : '';
}

function _clearSavedValues() {
	_clearSavedItems([
		'participant0',
		'description0',
		'participant1',
		'description1',

		'federalSubject',
		'city',
		'schoolType',
		'schoolName',
		'workName',
		'workDescription'
	]);
	_setItem('numberOfParticipants', 1);
}

function _clearSavedItems(keysArray = []) {
	keysArray.forEach( key => {
		localStorage.removeItem(key);
	});
}
