import React from "react";

import { connect } from 'react-redux';

import * as YMoneyConfig from '../config/YandexMoney';
import * as URLParser from '../services/URLParser';

import TopBar from '../components/TopBar';
import PaymentBlock from '../components/PaymentBlock';


const styles = {
	screenContainer:{
		boxSizing: 'border-box',
		overflowY: 'scroll',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		margin: 0,
		padding: '80px 0 10px 0px',
		textAlign: 'center',
		backgroundColor: '#EDEEF0'
	},

	contentBlock:{
		display:'block',
		float:'left',
		width:'100%',
		marginBottom: 20,
		backgroundColor: '#fff',
		boxShadow:'0px 1px 6px 0px rgba(0,0,0,0.21)'
	},

	description:{
		padding: '0 10px',
		textAlign: 'left',
		textJustify: 'kashida'
	},

	controlContainer:{
		display: 'block',
		boxSizing: 'border-box',
		width: '100%',
		padding: 12,
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#FF5F26'
	},

	textFieldContaier:{
		backgroundColor: 'white',
		padding: 12
	},

	textField: {
		width: '100%',
		overflowX: 'hidden',
		overflowY: 'hidden'
	}
}

class PaymentScreen extends React.Component {

	constructor(props){
		super(props);

		console.log({ props });

		// Break url and find required arguments
		let label = '';
		let receiver = '';
		let price = YMoneyConfig.ORDER_FALLBACK_PRICE;

		const urlParams = URLParser.getJsonFromUrl();
		if (urlParams.label && urlParams.receiver){
			label = urlParams.label;
			receiver = urlParams.receiver;
			price = urlParams.price;
		}
		else{
			console.error("No required arguments");
		}

		if (price < YMoneyConfig.ORDER_FALLBACK_PRICE || !price || price === 'undefined' || isNaN(price)){
			price = YMoneyConfig.ORDER_FALLBACK_PRICE;
		}

		this.state={
			receiver: receiver,
			price: price,
			label: label
		}
	}

	/* Visuals */
	_getStyleForTitle(windowWidth){
		const style={
			width: '100%',
			minHeight: 21,
			fontWeight:'normal',
			textAlign: 'center'
		};
		// For mobiles
		if(windowWidth < 500){
			return({
				...style,
				fontSize: 18,
			})
		}

		return({
			...style,
			fontSize: 24,
		});
	}

	_getStyleForContentFrame(windowWidth){
		// For mobiles
		if(windowWidth < 500){
			return({
				display: "block",
				padding: "0 10px 0 10px"
			});
		}
		
		return({
			display: "block",
			width: '90%',
			maxWidth: 650,
			marginLeft: 'auto',
			marginRight: 'auto',
		});
	}

	render() {
		const{
			windowWidth
		}=this.props;

		const {
			receiver,
			price,
			label
		}=this.state;

		const contentFrameStyle = this._getStyleForContentFrame(windowWidth);
		const titleStyle = this._getStyleForTitle(windowWidth);

		return (
			<div style={ styles.screenContainer }>
				<TopBar empty />
				<div style={ contentFrameStyle }>
					<h1 style={ titleStyle }>
						Внесите оргвзнос:
					</h1>
					<div style={ styles.contentBlock }>
						<div style={ styles.controlContainer }>
							<PaymentBlock
								receiver={ receiver }
								price={ price }
								label={ label }
								buttonText="Внести"
								shouldOpenInNewTab={ false }
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		...state.windowReducer
	};
}

export default connect(
	mapStateToProps
)(PaymentScreen);
