
export function getSearchQuery() {
	return window.location.search.substr(1);
}

export function getJsonFromUrl() {
	const query = getSearchQuery();
	const result = {};
	query.split("&").forEach(function(part) {
		let item = part.split("=");
		result[item[0]] = decodeURIComponent(item[1]);
	});
	return result;
}
