import React from "react";
import * as Routes from '../constants/Routes';

import { Link } from 'react-router-dom';

const styles = {
	frame:{
		position: "relative",
		display: "flex",
		width: "100%",
		height: "100%",
		borderRadius: 4,
		backgroundColor: "rgb(255, 255, 255)",
		boxShadow: "rgba(0, 0, 0, 0.21) 0px 8px 14px 0px"
	},

	img:{
		position: "absolute",
		top: "50%",
		transform: "translate(0px, -50%)",
		width: "100%",
		height: "auto",
		marginBottom: -4,
		borderRadius: 4
	}
}

export default function ContestPreview(props){
	const{
		id,
		src,
		onClick
	}=props;

	const containerStyle = {
		display: 'inline-block',
		width: '18%',
		minWidth: 210,
		height: 210,
		margin: '3vw',
	}

	return (
		<div
			onClick={ () => onClick(id) }
			style={ containerStyle }
		>
			<Link
				to={ `${Routes.CONTEST}/${id}` }
				style={ styles.frame }
			>
				<img
					src={ src }
					style={ styles.img }
					alt="Превью конкурса"
				/>
			</Link>
		</div>
	);
}