import {
	SET_WINDOW_SIZE,
	SET_WINDOW_SCROLL
} from '../types/window';

const initialState = {
	windowWidth: 960,
	windowHeight: 600,
	scrollX: 0,
	scrollY: 0
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_WINDOW_SIZE:
			return { ...state, windowWidth: action.width, windowHeight: action.height };
		case SET_WINDOW_SCROLL:
			return { ...state, scrollX: action.scrollX, scrollY: action.scrollY };
		default:
			return state;
	}
};