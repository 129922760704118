import React from "react";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import WindowAllActions from './redux/actions/window';

import { MuiThemeProvider } from 'material-ui/styles';
import { BrowserRouter } from 'react-router-dom';

import Main from './components/Main';

import './App.css'
import MUI_Theme from './config/Theme';


class App extends React.Component {

	constructor(props){
		super(props);

		this.state={}
	}

	_updateDimensions() {
		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;
		
		this.props.WindowActions.setWindowSize(windowWidth, windowHeight);
	}

	componentWillMount(){
		this._updateDimensions();
	}

	async componentDidMount() {
		window.addEventListener("resize", this._updateDimensions.bind(this));
		this._updateDimensions();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this._updateDimensions.bind(this));
	}

	render() {
		return (
			<BrowserRouter>
				<MuiThemeProvider theme={ MUI_Theme }>
					<Main windowWidth={ this.props.windowWidth } />
				</MuiThemeProvider>
			</BrowserRouter>
		);
	}
}

function mapStateToProps(state) {
	return {
		...state.windowReducer
	};
}

function mapDispatchToProps(dispatch) {
	return {
		WindowActions: bindActionCreators(WindowAllActions, dispatch),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(App);
