import React from "react";

const style = {
	width: '100%',
	minHeight: 21,
	fontWeight:'normal',
	textAlign: 'center'
};

export default function OverviewTitle(props){
	const{
		windowWidth,
		text,
		h1
	}=props;

	const st = windowWidth < 500 ? { ...style, fontSize: 18 } : { ...style, fontSize: 24 };

	if (h1){
		return(
			<h1 style={ st }>
				{ text }
			</h1>
		);
	}

	return(
		<h2 style={ st }>
			{ text }
		</h2>
	);
}