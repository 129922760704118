import { 
  SET_NUMBER_OF_UNFILLED_ORDERS
} from '../types/orders';

const initialState = {
	numberOfUnfilledOrders: 0
};

export default function orders(state = initialState, action) {

  switch (action.type) {

    case SET_NUMBER_OF_UNFILLED_ORDERS:{
      return {
        numberOfUnfilledOrders: action.numberOfUnfilledOrders
      };
    }
    default:
      return state;
  }
}
