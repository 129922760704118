import { ID } from '../config/YandexMetrika';
import { METRIKA } from '../constants/Analytics';


export const METRIKA_GOALS = METRIKA.GOALS;

export const reachGoal = (goalName) => {
	if (!!window.ANALYTICS && !!window.ANALYTICS.ym) {
		try {
			window.ANALYTICS.ym(ID, 'reachGoal', goalName);

			if (process.env.NODE_ENV === 'development')
				console.log("Analytics Service reach goal: ", goalName);
		}
		catch(error) {
			return _handleError(error);
		}
	}
	else {
		console.warn("No Yandex Metrika object");
	}
}

function _handleError(err) {
	console.warn("Analytics Service Error: ", { err });
}
