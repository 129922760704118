/* RemoteLogger */

import axios from "axios";

import {
	LOG_ADD
} from '../constants/API';

const post = (url, params={})=>{
	return new Promise((resolve, reject)=>{
		axios.post(url, params)
		.then((res)=>{
			const isOK = res.statusText === "OK";
			const response = {...res, ok:isOK};
			return resolve(response);
		})
		.catch((ex)=>reject(ex));
	});
};

const isProduction = () => {
	return (process.env.NODE_ENV === "production");
}

const log = (messageObject) => {
	if (isProduction()){
		const data = {
			message: JSON.stringify(messageObject),
			is_error: false
		};
		post(LOG_ADD, data)
		.then((res)=>{})
		.catch((ex)=>console.error(ex))
	}
	console.log(messageObject);
};

const error = (messageObject) => {
	if (isProduction()){
		const data = {
			message: JSON.stringify(messageObject),
			is_error: true
		};
		post(LOG_ADD, data)
		.then((res)=>{})
		.catch((ex)=>console.error(ex))
	}
	console.error(messageObject);
};

export default {
	log,
	error
};