module.exports = {
	desktopContainer:{
		boxSizing: 'border-box',
		overflowY: 'scroll',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		margin: 0,
		padding: '80px 0 10px 0px'
	},

	mobileContainer:{
		padding: '80px 0 10px 0px'
	},

	contentBlock:{
		display:'block', 
		float:'left',
		textAlign: 'center',
		width:'100%',
		marginBottom: 20,
		backgroundColor: '#fff',
		boxShadow:'0px 1px 6px 0px rgba(0,0,0,0.21)'
	},

	description:{
		padding: '0 10px',
		textAlign: 'left',
		textJustify: 'kashida'
	},

	bottomBlock:{
		display: 'block',
		float: 'left',
		width: '100%',
		height: 36
	}
}