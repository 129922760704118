import React from "react";
import { HamburgerButton } from 'react-hamburger-button';

const styles = {
	container:{
		display: 'flex',
		margin: 10,
		padding: "6px 0 0 18vw"
	},

	label: {
		display: 'inline-block',
		margin: '0 10px',
		fontSize: 22
	},

	hamburger:{
		display: 'inline-block'
	}
}

export default function MobileMenuButton(props){
	const{
		isOpen,
		onClick
	}=props;

	return(
		<div style={ styles.container } onClick={ onClick }>
			<label style={ styles.label }>
				МЕНЮ
			</label>
			<HamburgerButton
				open={ isOpen }
				width={ 30 }
				height={ 24 }
				color="#333"
				style={ styles.hamburger }
			/>
		</div>
	)
}