import React from "react";

import * as YMoneyConfig from '../config/YandexMoney';
import * as Routes from '../constants/Routes';

import Button from 'material-ui/Button';
import { Link } from 'react-router-dom';

const styles = {
	payButton:{
		width: '100%',
		margin: '0 auto',
		color: 'white',
		backgroundColor: '#FFA339',
	}
};

export default function PaymentButton(props){
	const{
		orderId,
		price,
		onClick
	}=props;

	const label = `${YMoneyConfig.LABEL_PREFIX}${orderId}`;

	return(
		<Link
			to={ `${Routes.PAYMENT}?label=${label}&receiver=${YMoneyConfig.RECEIVER}&price=${price}` }
			target="_blank"
			rel="nofollow noopener"
		>
			<Button
				type="submit"
				variant="raised"
				style={ styles.payButton }
				onClick={ ()=>{ if (!!onClick) onClick.call() } }
			>
				Внести
			</Button>
		</Link>
	);
}