import React from "react";

import { connect } from 'react-redux';

import TopBar from '../components/TopBar';

const styles = {
	screenContainer:{
		boxSizing: 'border-box',
		overflowY: 'scroll',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		margin: 0,
		padding: '80px 0 10px 0px',
		textAlign: 'center',
		backgroundColor: '#EDEEF0'
	}
}

class PaymentCreditedScreen extends React.Component {

	constructor(props){
		super(props);

		this.state={}
	}

	/* Visuals */
	_getStyleForTitle(windowWidth){
		const style={
			width: '100%',
			minHeight: 21,
			fontWeight:'normal',
			textAlign: 'center'
		};

		// For mobiles
		if(windowWidth < 500){
			return({
				...style,
				fontSize: 18
			});
		}

		return({
			...style,
			fontSize: 24
		});
	}

	_getStyleForContentFrame(windowWidth){
		// For mobiles
		if(windowWidth < 500){
			return({
				display: "block",
				padding: "0 10px 0 10px"
			});
		}
		
		return({
			display: "block",
			width: '90%',
			maxWidth: 650,
			marginLeft: 'auto',
			marginRight: 'auto',
		});
	}

	render() {
		const{
			windowWidth
		}=this.props;

		const contentFrameStyle = this._getStyleForContentFrame(windowWidth);
		const titleStyle = this._getStyleForTitle(windowWidth);

		return (
			<div style={ styles.screenContainer }>
				<TopBar empty />
				<div style={ contentFrameStyle }>
					<h1 style={ titleStyle }>
						Платёж одобрен!
					</h1>
					<p>
						Можете закрыть данное окно.
					</p>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		...state.windowReducer
	};
}

export default connect(
	mapStateToProps
)(PaymentCreditedScreen);
