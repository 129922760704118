import React from "react";

import Button from 'material-ui/Button';
import List, {
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from 'material-ui/List';
import IconButton from 'material-ui/IconButton';

const styles = {
	input: {
		display: 'none'
	},

	button:{
		width: '100%',
		color: 'white',
		backgroundColor: '#FFA339'
	},

	errorText:{
		color: '#f44336'
	}
}

export default class PhotoDropList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			files: [],
			errorText: null
		};
	}

	_onDrop(e) {
		const targetFiles = e.target.files;
		const selectedFiles = Array.prototype.slice.call(targetFiles);

		const{
			onFilesDrop,
			onError,
			maxFilesCount
		}=this.props;

		let files = [...this.state.files];

		let filesCount = files.length;
		let errorText = '';
		if (selectedFiles.length > (maxFilesCount - filesCount)){
			errorText = 'Выбраны первые ' + maxFilesCount + ' файлов';
		}
		
		filesCount = maxFilesCount - filesCount;

		for(let i = 0; i < filesCount; i++){
			const file = selectedFiles[i];
			if (!file)
				break;
			const fileName = file.name;
			const ext = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
			if (ext === "png" || ext === "jpg" || ext === "jpeg"){

				if (!this._checkIfFilesArrayContainsFile(files, file)){
					files.push(file);
				}
				else{
					errorText = 'Вы попытались выбрать 2 одинаковых изображения.';
				}
			}
			else{
				const msg = 'Изображение должно быть с расширением PNG, JPG, JPEG';
				if (onError)
					onError(msg);
				errorText = msg;
			}
		}

		this.setState({
			files,
			errorText
		});

		if (onFilesDrop)
			onFilesDrop(files);
	}

	_checkIfFilesArrayContainsFile(files, fileToCheck){
		let contains = false;
		for(let i=0; i < files.length; i++){
			let file = files[i];
			if (file.name === fileToCheck.name){
				contains = true;
				break;
			}
		} 
		return contains;
	}

	_handleRemoveFileAction(file) {
		const files = this.state.files.filter(f => f !== file);
		this.setState({ files }); 
	}

	_renderFileRow(file){
		return(
			<ListItem key={file.name}>
				<ListItemText
					primary={file.name}
				/>
				<ListItemSecondaryAction>
					<IconButton
						onClick={ this._handleRemoveFileAction.bind(this, file) }
						aria-label="Убрать"
					>
						{"X"}
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
		);
	}

	render(){
		const{
			files,
			errorText
		}=this.state;

		return(
			<div>
				<Button variant="raised" style={ styles.button }>
					<label>
						<input style={ styles.input } type="file" multiple onChange={ this._onDrop.bind(this) }/>
						{ files.length === 0 ? this.props.title : "Добавить фото" }
					</label>
				</Button>
				{ errorText ? <p style={ styles.errorText }>{ errorText }</p> : null }
				<List>
					{ files.map(file => this._renderFileRow(file)) }
				</List>
			</div>
		);
	}
}