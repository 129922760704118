export const HOST = process.env.NODE_ENV === "production" ? "https://doshkolnik.now.sh" : "http://localhost:4000";

export const MAIN = "/";
export const MY_ORDERS = "/mine";
export const BECOME_MEMBER = '/become_member';
export const CONTEST = "/contest";
export const PARTICIPATE = "/participate";


export const PAYMENT = "/payment";
export const PAYMENT_CREDITED = PAYMENT + '/credited';