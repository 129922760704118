import React from "react";
import PropTypes from 'prop-types';
import * as Routes from '../../constants/Routes';

import Button from 'material-ui/Button';
import { Link } from 'react-router-dom';
import MobileMenuButton from './MobileMenuButton';

import Badge from 'material-ui/Badge';

const styles = {
	topBar:{
		zIndex: 100000,
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		height: 60,
		boxShadow:'0px 8px 14px 0px rgba(0,0,0,0.21)',
		backgroundColor: '#E5C59A'
	},

	topBarLeftContainer:{
		display: 'block',
		float: 'left',
		height: 60,
		padding: 12,
		paddingLeft: 18
	},

	topBarMiddleContainer:{
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		height: 60,
		textAlign: 'center'
	},

	topBarRightContainer:{
		display: 'block',
		float: 'right',
		width: 200,
		height: 60,
		padding: 12
	},

	topBarLogo:{
		display: 'block',
		float: 'left',
		height: 50,
		padding: 5
	},

	topBarTitle:{
		fontSize: 20,
		height: 36,
		padding: 8
	},

	navbarLinksContainer:{
		display: "block",
		boxShadow:'0px 8px 14px 0px rgba(0,0,0,0.21)',
		backgroundColor: '#E5C59A',
		textAlign: 'center'
	},

	navbarLink:{
		display: "flex",
		justifyContent: "space-around",
		width: "100%",
		height: 40,
		paddingTop: 5,
		textAlign: 'center',
		borderTop: "1.2px solid #b7a082"
	},

	badgeMobile: {
		right: "-120%"
	},

	badgeDesktop: {
		marginTop: "-20%",
		right: "-120%"
	}
}

const linksArray = [
	{
		title:"Текущие конкурсы",
		link: Routes.MAIN
	},
	{
		title:"Мои работы",
		link: Routes.MY_ORDERS
	}
];

export default class TopBar extends React.Component {

	constructor(props){
		super(props);
		this.state={
			isNavBarMenuOpen: false
		}
	}

	_handleNavBarToggle(){
		const isOpen = this.state.isNavBarMenuOpen
		const isNavBarMenuOpen = !isOpen;
		this.setState({ isNavBarMenuOpen })
	}

	_renderBadge(number, isMobile){


		const style = isMobile ? styles.badgeMobile : styles.badgeDesktop;

		return(
			<Badge 
				badgeContent={ number }
				color="secondary"
				style={ style }
				children={ [] }
			/>
		);
	}

	_renderLinksForBigScreen(numberOfUnfilledOrders){
		const links = [];
		linksArray.forEach((linkObj, index)=>{
			links.push(
				<Link
					key={index}
					to={linkObj.link}
				>
					<Button>
						{ index === 1 && numberOfUnfilledOrders > 0 ? this._renderBadge(numberOfUnfilledOrders, false) : "" }
						{linkObj.title}
					</Button>
				</Link>
			);
		});

		return links;
	}

	 _renderLinksForMobileScreen(numberOfUnfilledOrders){
		const links = [];
		linksArray.forEach((linkObj, index)=>{
			links.push(
				<Link 
					key={ index }
					to={ linkObj.link }
					onClick={ this._handleNavBarToggle.bind(this) }
					style={ styles.navbarLink }
				>
					<Button>
						{ index === 1 && numberOfUnfilledOrders > 0 ? this._renderBadge(numberOfUnfilledOrders, true) : "" }
						{linkObj.title}
					</Button>
				</Link>
			);
		});

		return(
			<div style={ styles.navbarLinksContainer }>
				{ links }
			</div>
		);
	}

	render() {
		const{
			windowWidth,
			numberOfUnfilledOrders,
			empty
		}=this.props;

		if (!!empty)
			return(
				<div style={ styles.topBar }>
					<img 
						src={ '/logo.png' }
						alt="Дошкольник конкурс лого"
						style={ styles.topBarLogo }
					/>
				</div>
			);

		const{
			isNavBarMenuOpen
		}=this.state;

		let barContent;
		let menuList = <div></div>;

		if (windowWidth < 500){
			barContent= <MobileMenuButton isOpen={ isNavBarMenuOpen } onClick={ this._handleNavBarToggle.bind(this) }/>;

			if (isNavBarMenuOpen){
				menuList = this._renderLinksForMobileScreen(numberOfUnfilledOrders);
			}
		}
		else{
			barContent = 
				<div style={ styles.topBarLeftContainer }>
					{ this._renderLinksForBigScreen(numberOfUnfilledOrders) }
				</div>;
		}

		return (
			<div style={ styles.topBar }>
				<Link 
					to={ Routes.MAIN }
				>
					<img 
						src={ '/logo.png' }
						alt="Дошкольник конкурс лого"
						style={ styles.topBarLogo }
					/>
				</Link>
				{ barContent }
				{ menuList }
			</div>
		);
	}
}

TopBar.propTypes = {
	windowWidth:PropTypes.number
};
